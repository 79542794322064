<template>
  <div id="mainContainer">
    <v-row no-gutters>

      <div style="margin-top: 10px;">
        <div class="sectionLargeIcon largeServerIcon"></div>
        <div class="widget clearfix">
          <span class="title"
            style="font-size: 22px; margin-right: 15px;  font-weight: 600 !important; color: #183677;">דוחות</span>
          <div class="widget_inside" style="margin-top: 30px;">

            <v-btn @click="openSelected('reports1')" style="margin-left: 20px;">
              מוניטור הזמנות
            </v-btn>

            <v-btn @click="openSelected('reports2')" style="margin-left: 20px;">
              חפק אונליין
            </v-btn>

            <v-btn @click="openSelected('reports3')" style="margin-left: 20px;">
              דוח הכנסות
            </v-btn>

            <v-btn @click="openSelected('reports4')" style="margin-left: 20px;">
              דוח לפי דגם
            </v-btn>

            <v-btn @click="downloadExcel()">
              הורד דוח כללי
            </v-btn>

            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
              </v-col>
            </v-row>
            <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

            <FirstReportsComp v-if="reports1" />
            <SecondReportsComp v-if="reports2" />
            <ThirdReportsComp v-if="reports3" />
            <FourthReportsComp v-if="reports4" />
          </div>
        </div>
      </div>
    </v-row>
  </div>
</template>
<script>
import FirstReportsComp from '@/components/reports/FirstReportsComp'
import SecondReportsComp from '@/components/reports/SecondReportsComp'
import ThirdReportsComp from '@/components/reports/ThirdReportsComp'
import FourthReportsComp from '@/components/reports/FourthReportsComp'
import * as XLSX from 'xlsx/xlsx.mjs';
import ApiServices from '@/services/api.service'

export default {
  name: 'agent',
  components: {
    FirstReportsComp,
    SecondReportsComp,
    ThirdReportsComp,
    FourthReportsComp
  },
  data: () => ({
    reports1: true,
    reports2: false,
    reports3: false,
    reports4: false,
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    allCustomersAndOrdersForExcel: [],
  }),
  methods: {
    openSelected(reportsNum) {
      if (reportsNum === "reports1") {
        this.reports1 = true;
        this.reports2 = false;
        this.reports3 = false;
        this.reports4 = false;
      } else if (reportsNum === "reports2") {
        this.reports1 = false;
        this.reports2 = true;
        this.reports3 = false;
        this.reports4 = false;
      } else if (reportsNum === "reports3") {
        this.reports1 = false;
        this.reports2 = false;
        this.reports3 = true;
        this.reports4 = false;
      } else {
        this.reports1 = false;
        this.reports2 = false;
        this.reports3 = false;
        this.reports4 = true;
      }
    },

    async downloadExcel() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + "/reports/get_all_details_report";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          console.log(jsonObject);
          const transformedData = this.transformData(jsonObject);
          this.allCustomersAndOrdersForExcel = transformedData;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
      this.progressShow = false;

      const wb = XLSX.utils.book_new();
      const ws_name = "Customer Data";

      // Create headers dynamically based on the fetched data
      let fixedHeaders = ["family", "father_name", "mother_name", "father_id", "mother_id", "phone1", "phone2", "phone3", "phone4",
        "email", "address", "city", "appartment", "community", "status", "powerLink_id", "mode", "order_details.shop_station", "order_details.hour_to_come",
        "order_details.day_to_come", "order_details.possible_quantity", "order_details.ordered_quantity", "order_details.ordered_purchased",
        "order_details.ordered_exchanged", "order_details.arrival_status", "order_details.balance", "payDetails"];

      const ws = XLSX.utils.json_to_sheet(this.allCustomersAndOrdersForExcel, { header: fixedHeaders });
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      // Write the workbook to a file and trigger download
      XLSX.writeFile(wb, "fullReport.xlsx");
    },
    transformData(data) {
      return data.map(item => ({
        family: item.family,
        father_name: item.father_name,
        mother_name: item.mother_name,
        father_id: item.father_id,
        mother_id: item.mother_id,
        phone1: item.phone1,
        phone2: item.phone2,
        phone3: item.phone3,
        phone4: item.phone4,
        email: item.email,
        address: item.address,
        city: item.city,
        appartment: item.apartment,  // Ensure the key matches your data structure
        community: item.community,
        status: item.status,
        powerLink_id: item.powerLink_id,
        mode: item.mode,
        "order_details.shop_station": item.order_details.shop_station,
        "order_details.hour_to_come": item.order_details.hour_to_come,
        "order_details.day_to_come": item.order_details.day_to_come,
        "order_details.possible_quantity": item.order_details.possible_quantity,
        "order_details.ordered_quantity": item.order_details.ordered_quantity,
        "order_details.ordered_purchased": item.order_details.ordered_purchased,
        "order_details.ordered_exchanged": item.order_details.ordered_exchanged,
        "order_details.arrival_status": item.order_details.arrival_status,
        "order_details.balance": item.order_details.balance,
        payDetails: JSON.stringify(item.pay_details)  // Convert array or object to string if needed
        // payDetails: await cleanPayDetails(item.pay_details)  // Convert array or object to string if needed
      }));
    },
    // cleanPayDetails(payDetailsArray) {
    //   return payDetailsArray.map(pd => {
    //     const { _id, customer_id, __v, ...cleanDetails } = pd;
    //     cleanDetails.pay_type = cleanDetails.pay_type.map(pt => {
    //       const { check_details, ...cleanPayType } = pt;
    //       return cleanPayType;
    //     });
    //     return JSON.stringify(cleanDetails);
    //   });
    // },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
  },
  created() {

  },
}
</script>
<style scoped></style>