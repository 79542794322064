<template>
    <v-dialog v-model="openMode" persistent max-width="700px">
        <v-card style="background-color: #eee; padding-bottom: 30px;">
            <div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="#3F51B5" large @click="openMode = false">
                            mdi-window-close
                        </v-icon>
                    </template>
                    <span>חזור לתשלום</span>
                </v-tooltip>
            </div>

            <div
                style="width:60%;max-width:100%;border:2px solid cadetblue;padding:20px;margin-right:auto;margin-left:auto; background-color: white">
                <div style="text-align:center">
                    <h3>דף תשלום</h3>
                </div>

                <span class="d-flex justify-center" style="color: #1ba39c; font-weight: 600; margin-bottom: 20px;">סכום
                    לתשלום: {{ " " +
                        amount + ' ש"ח' }}</span>

                <v-text-field outlined dense v-model="cardDetails" autofocus @change="chargeCard"
                    label="העבר כרטיס"></v-text-field>

                <v-select outlined dense :items="tashlumimOptions" v-model="userInput.tashlumimChoosed"
                    label="בחר מספר תשלומים"></v-select>

                <div style="vertical-align:top;text-align:right;margin:15px 0px 15px 0px;min-width:100%">
                    <span style="text-align:right; min-width:100%;margin-right:1px;color:#808080">מספר כרטיס:</span><br>
                    <input v-model="userInput.cardNumber" type="number" maxlength="30" class="TextBox"><br>
                </div>

                <div style="vertical-align:top;text-align:right;margin:15px 0px 15px 0px;min-width:100%">
                    <span style="text-align:right; min-width:100%;margin-right:1px;color:#808080">תוקף:</span><br>
                    <input v-model="userInput.tokef" type="number" class="TextBox"><br>
                </div>

                <div style="vertical-align:top;text-align:right;margin:15px 0px 15px 0px;min-width:100%">
                    <span style="text-align:right; min-width:100%;margin-right:1px;color:#808080">3 ספורת בטיחות:</span><br>
                    <input v-model="userInput.cvv" type="number" class="TextBox"><br>
                </div>

                <div id="OkDiv" style="font-weight:bold;color:#47ba18;padding:5px;display:none;text-align:center">
                    Transaction
                    Done - העסקה בוצעה בהצלחה</div>

                <div id="PayBtDiv" style="margin:20px 0px 15px 0px;text-align:center">
                    <input type="button" id="PayBt"
                        style="cursor:pointer;color:white;background-color:#17a2b8;text-align:center" value="ביצוע תשלום"
                        class="TextBox" @click="PayBtClick()">
                    <div id="ErrorDiv" style="font-weight:bold;color:firebrick;padding:5px"></div>
                </div>
                <div style="text-align:center;padding:10px 0px;font-family:Assistant,Arial;color:#808080;display:none"
                    id="WaitPay"><img src="@/assets/waitnew.gif" style="width:50px;"><br>מבצע חיוב, נא להמתין...
                </div>

                <div id="Result" style="text-align:center" dir="ltr"></div>
            </div>
        </v-card>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'

export default {
    props: {
        customer: Object,
        amount: Number,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        progressShow: true,
        userInput: {
            family: "",
            address: "",
            city: "",
            phone1: "",
            email: "",
            cardNumber: "",
            tokef: "",
            cvv: "",
            tashlumimChoosed: 1,
        },
        cardDetails: "",
        tashlumimOptions: [1, 2],
    }),
    methods: {
        async PayBtClick() {
            if (this.amount && this.amount > 0) {
                document.getElementById('Result').innerHTML = ''
                document.getElementById('PayBtDiv').style.display = 'none';
                document.getElementById('OkDiv').style.display = 'none';
                document.getElementById('WaitPay').style.display = 'block';
                document.getElementById('ErrorDiv').innerHTML = '';

                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ amount: this.amount, userInput: this.userInput });

                let api = process.env.VUE_APP_BASE_URL + "/kupa/pay_nedarim";
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                const jsonObject = await res.json();
                if (res.status === 400 || res.status === 404) {
                    console.log(jsonObject);
                    document.getElementById('ErrorDiv').innerHTML = jsonObject.message
                    document.getElementById('WaitPay').style.display = 'none';
                    document.getElementById('PayBtDiv').style.display = 'block';
                } else if (res.status === 200) {
                    this.$emit('paySuccess', this.amount, { type: "creditCard", amount: this.amount, last_digits: this.userInput.cardNumber.slice(-4) });
                    document.getElementById('WaitPay').style.display = 'none';
                    document.getElementById('OkDiv').style.display = 'block';
                    setTimeout(() => {
                        this.closeDialog();
                    }, 800);
                }
            } else {
                document.getElementById('ErrorDiv').innerHTML = "הסכום לתשלום הוא 0 שח, ניתן לשלם מ1 שח ומעלה"
                document.getElementById('WaitPay').style.display = 'none';
                document.getElementById('PayBtDiv').style.display = 'block';
            }
        },

        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true;
        },
        closeDialog() {
            this.openMode = false;
        },
        chargeCard(value) {
            console.log(value);
            // Split the value at the equals sign
            const parts = value.split('=');
            // Assuming the first part is the card number
            const cardNumber = parts[0];
            // Assuming the next four digits after the equals sign represent the expiration date in the format "yymm"
            const expirationData = parts[1].substring(0, 4);
            // Format the expiration date as "mm/yy" for readability
            const expirationDate = expirationData.substring(2) + expirationData.substring(0, 2);

            this.userInput = { ...this.userInput, cardNumber: cardNumber, tokef: expirationDate };
            this.cardDetails = "";
        }

    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.userInput = JSON.parse(JSON.stringify(this.customer));
        this.userInput.tashlumimChoosed = 1;
    }
}
</script>
<style scoped>
/* This will hide the spin button in Chrome, Edge, Safari, and Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* This will hide the spin button in Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.TextBox {
    -webkit-appearance: none;
    font-family: 'Assistant', sans-serif;
    font-size: large;
    color: black;
    width: 100%;
    text-align: right;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 2px;
    box-sizing: content-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-top: 2px;
    outline: none;
}

@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGSV35Gu.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGiV35Gu.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGaV3w.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
