import { render, staticRenderFns } from "./FirstReportsComp.vue?vue&type=template&id=3050275f&scoped=true&"
import script from "./FirstReportsComp.vue?vue&type=script&lang=js&"
export * from "./FirstReportsComp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3050275f",
  null
  
)

export default component.exports