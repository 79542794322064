<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6" v-if="formTitle !== 'ערוך הזמנה'">
                                <v-text-field v-model="itemEdited.card_id" type="number" hide-spin-buttons label="ת.ז"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.size" label="מידה" type="number" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="genders" v-model="itemEdited.gender" hide-details outlined dense
                                    label="בן/בת" :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="types" v-model="itemEdited.type" hide-details outlined dense label="סוג"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-select :items="status" :item-text="'hebrewName'" :item-value="'name'" hide-details
                                    v-model="itemEdited.status" outlined dense label="סטטוס"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row style="margin-top: 20px;">
                        <v-col cols="12" style="display: flex; justify-content: center;">
                            <v-btn color="#3F51B5" text @click="openMode = false">
                                ביטול
                            </v-btn>
                            <v-btn color="#3F51B5" text
                                @click="formTitle === 'ערוך הזמנה' ? saveEditedOrder() : saveNewOrder()">
                                שמור
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row style="display: flex; justify-content: center;">
                        <v-col cols='12' xl="9">
                            <div style="background-color: white; border-radius: 15px;">
                                <div>
                                    <div class="d-flex justify-center pt-1"
                                        style="background-color: rgba(0, 0, 255, 0.61); border-radius: 15px 15px 0px 0px;">
                                        <span class="text-center "
                                            style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">מצב
                                            מלאי</span>
                                    </div>
                                    <div class="divider2"></div>
                                </div>
                                <div style="height: 300px; overflow-y: auto; background-color: white; border-radius: 15px;">
                                    <v-data-table id="table1" :headers="stockHeaders" :items="productsAvaliableFiltered"
                                        sort-by="index" class="elevation-0" hide-default-footer
                                        :items-per-page="productsAvailable.length">

                                        <!-- <template v-slot:top>
                                            <div class="d-flex justify-center pt-1"
                                                style="background-color: rgba(0, 0, 255, 0.61);">
                                                <span class="text-center "
                                                    style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">מצב
                                                    מלאי</span>
                                            </div>
                                            <div class="divider2"></div>
                                        </template> -->

                                        <template v-slot:no-data>
                                            <v-row v-if="progressShow" class="my-5">
                                                <v-col class="d-flex justify-center">
                                                    <v-progress-circular indeterminate
                                                        color="#3F51B5"></v-progress-circular>
                                                </v-col>
                                            </v-row>
                                            <div class="ma-5">
                                                <span class="text-center"
                                                    style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                                                    לא נמצאו פריטים</span>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import Auth from '@/services/auth.service';

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        customerZeut: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        createdByUser: "",
        itemEdited: {
            _id: "",
            card_id: "",
            status: "",
            size: "",
            gender: "",
            type: "",
        },
        genders: ["בן", "בת"],
        types: ["שבת", "חול"],
        status: [{ hebrewName: "הוזמן טלפונית", name: "order_by_phone" }, { hebrewName: "הוזמן ידנית", name: "order_manually" }],
        stockHeaders: [
            { text: 'מידה', value: 'size' },
            { text: 'בן/בת', value: 'gender' },
            { text: 'סוג', value: 'type' },
            { text: 'כמות זמינה', value: 'quantity_available' },
        ],
        productsAvailable: [],
    }),

    methods: {
        async saveNewOrder() {

            if (this.$refs.form.validate()) {

                const orderJSON = JSON.stringify({ order: this.itemEdited, createdBy: this.createdByUser });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/orders/create_order";
                    const res = await fetch(api, ApiServices.requestOptions("POST", orderJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בהוספת הזמנה: " + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id;
                        this.$emit("orderAdded", this.itemEdited, "ההזמנה נוצרה בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved order: " + error, "red");
                }
            }

        },
        async saveEditedOrder() {
            if (this.$refs.form.validate()) {

                const orderJSON = JSON.stringify({ order: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/orders/edit_order";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", orderJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בעריכת הזמנה" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("orderEdited", this.itemEdited, "ההזמנה עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit order: " + error, "red");

                }
            }
        },
        async getProductsAvailable() {
            let token = localStorage.getItem("token");
            try {
                let api = process.env.VUE_APP_BASE_URL + "/orders/get_products_available";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status === 400) {
                } else if (res.status === 200) {
                    this.productsAvailable = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get products: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        productsAvaliableFiltered() {
            if (this.itemEdited.size) {
                return this.productsAvailable.filter(product => product.size === this.itemEdited.size);
            }
            return this.productsAvailable;
        }
    },
    mounted() {
        this.getProductsAvailable();
        if (this.formTitle === "ערוך הזמנה") {
            this.itemEdited.status = JSON.parse(JSON.stringify(this.itemToEdit.status));
            this.itemEdited._id = JSON.parse(JSON.stringify(this.itemToEdit._id));
            this.itemEdited.size = JSON.parse(JSON.stringify(this.itemToEdit.size));
            this.itemEdited.gender = JSON.parse(JSON.stringify(this.itemToEdit.gender));
            this.itemEdited.type = JSON.parse(JSON.stringify(this.itemToEdit.type));
            console.log(this.itemEdited.status);
            this.itemEdited.status = this.itemEdited.status === "נרכש" ? "paid" : this.itemEdited.status === "הוזמן ידנית" ? "order_manually" : "order_by_phone";
        } else {
            this.createdByUser = Auth.getUserName();
            if (this.customerZeut) {
                this.itemEdited.card_id = JSON.parse(JSON.stringify(this.customerZeut));
            }
        }

    },
}
</script>
<style></style>
  