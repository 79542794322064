<template>
    <v-container>
        <div style="margin-top: 10px; margin-bottom: 40px;">
            <v-row class="d-flex justify-center">
                <v-col cols='12' xl='9'>
                    <v-card
                        style="padding: 1%; background: linear-gradient(180deg, rgba(219,221,233,1) 1%, rgba(225,196,255,1) 70%); box-shadow: 0 0 10px #fff;">
                        <div style="text-align: center; margin-bottom: 40px;">
                            <span class="headline">קופה רושמת</span>
                        </div>
                        <v-row>
                            <v-col cols="12" md="6" style="padding-right: 50px;">
                                <div>
                                    <h3>משפחת {{ customer.family }}</h3>
                                    <h3>{{ customer.address }} {{ customer.apartment }}</h3>
                                    <h3>{{ customer.city }}</h3>
                                </div>
                                <div style="margin-top: 30px;">
                                    <span style="font-size: x-large; font-weight: 600;">זכאי לעוד: </span>
                                    <span style="font-size: x-large; font-weight: 600; color: green;">{{
                                        productCountPermit
                                    }}
                                    </span>
                                    <span style="font-size: x-large; font-weight: 600;">נעליים</span>
                                </div>
                                <div style="margin-top: 20px;">
                                    <v-data-table id="table1" :headers="cartHeaders" :items="customerCart" sort-by="index"
                                        class="elevation-0" hide-default-footer>

                                        <template v-slot:top>
                                            <div class="d-flex justify-center pt-1"
                                                style="background-color: rgba(0, 0, 255, 0.61); ">
                                                <span class="text-center "
                                                    style="font-weight: bold; font-size: 25px; font-family: Times, serif; color: white;">פירוט
                                                    הקנייה</span>
                                            </div>
                                            <div class="divider2"></div>
                                        </template>

                                        <template v-slot:item.index="{ item, index }">
                                            <tr>
                                                <td>{{ index + 1 }}</td>
                                            </tr>
                                        </template>

                                        <template v-slot:no-data>
                                            <v-row v-if="progressShow" class="my-5">
                                                <v-col class="d-flex justify-center">
                                                    <v-progress-circular indeterminate
                                                        color="#3F51B5"></v-progress-circular>
                                                </v-col>
                                            </v-row>
                                            <div class="ma-5">
                                                <span class="text-center"
                                                    style="font-weight: bold; font-size: 25px; font-family: Times, serif;">העגלה
                                                    ריקה</span>
                                            </div>
                                        </template>

                                        <template v-slot:item.actions="{ item, index }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon v-on="on" color="#3F51B5" medium
                                                        @click="removeFromCart(item, index)">
                                                        mdi-delete-outline
                                                    </v-icon>
                                                </template>
                                                <span>הסר מהרשימה</span>
                                            </v-tooltip>
                                        </template>

                                    </v-data-table>

                                </div>
                                <div style="margin-top: 30px;">
                                    <span style="font-size: x-large; font-weight: 600;">סה"כ לתשלום: </span>
                                    <span style="font-size: x-large; font-weight: 600; color: green;">{{ amountToPay }}
                                    </span>
                                    <span style="font-size: x-large; font-weight: 600;">₪</span>
                                </div>

                                <div style="background-color: white; border-radius: 15px; margin-top: 50px;">
                                    <div>
                                        <div class="d-flex justify-center pt-1"
                                            style="background-color: rgba(114, 27, 63, 0.836); border-radius: 15px 15px 0px 0px;">
                                            <span class="text-center "
                                                style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">נעליים
                                                שהוזמנו</span>
                                        </div>
                                        <div class="divider2"></div>
                                    </div>
                                    <div
                                        style="height: 250px; overflow-y: auto; background-color: white; border-radius: 15px;">
                                        <v-data-table id="table1" :headers="orderedHeaders"
                                            :items="customerPreOrdersForShow" sort-by="index" class="elevation-0"
                                            hide-default-footer :items-per-page="customerPreOrdersForShow.length">

                                            <template v-slot:item.index="{ item, index }">
                                                <tr>
                                                    <td>{{ index + 1 }}</td>
                                                </tr>
                                            </template>

                                            <template v-slot:no-data>
                                                <v-row v-if="progressShow" class="my-5">
                                                    <v-col class="d-flex justify-center">
                                                        <v-progress-circular indeterminate
                                                            color="#3F51B5"></v-progress-circular>
                                                    </v-col>
                                                </v-row>
                                                <div class="ma-5">
                                                    <span class="text-center"
                                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                                                        לא נמצאו פריטים</span>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </div>
                            </v-col>


                            <v-col cols="12" md="6" style="padding-left: 40px;">
                                <div style="text-align: center; margin-bottom: 40px;">
                                    <h2 style="color: rgb(114, 27, 63); font-weight: 600;">סרוק נעל לקנייה</h2>
                                </div>
                                <div style="text-align: center; margin-bottom: 50px;">
                                    <img src="@/assets/img_shoes.png" style="width: 300px; max-width: 100%;">
                                </div>
                                <v-row class=" d-flex justify-center" style="margin-bottom: 60px;">
                                    <v-col cols="5" background-color="white" class="border-text-field"
                                        style="background-color: white; padding: 0px 10px 10px 10px; border-radius: 0px 10px 10px 0px;">
                                        <v-text-field v-model="barcode" autofocus label="ברקוד מוצר" ref="barcodeInput"
                                            @keydown="handleKeydown" type="number" hide-details hide-spin-buttons
                                            flat></v-text-field>
                                    </v-col>
                                    <v-col cols="1" background-color="white" class="border-text-field d-flex justify-center"
                                        style="background-color: white; padding: 0px 10px 10px 10px; border-radius: 10px 0px 0px 10px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" style="cursor: pointer;" @click="barcode = ''">
                                                    mdi-close
                                                </v-icon>
                                            </template>
                                            <span>נקה שדה</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                                <v-row class=" d-flex justify-center" style="margin-bottom: 60px;">
                                    <v-col cols="auto">
                                        <div @click="payDialog = true"
                                            style="background-color: #fff; padding: 5px; border-radius: 20px; margin-left: 15px;">
                                            <v-tooltip bottom>

                                                <template v-slot:activator="{ on }">
                                                    <img v-on="on" src="@/assets/icon_move_to_pay.svg"
                                                        style="width: 77px; max-width: 100%; cursor: pointer;">
                                                </template>
                                                <span>מעבר לתשלום</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto">
                                        <div @click="backToKupa1()"
                                            style="background-color: #fff; padding: 10px; border-radius: 20px; ">
                                            <v-tooltip bottom>

                                                <template v-slot:activator="{ on }">
                                                    <img v-on="on" src="@/assets/icon_cancle.svg"
                                                        style="width: 70px; max-width: 100%; cursor: pointer;">
                                                </template>
                                                <span>ביטול קנייה</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>

                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <span style="color: rgb(114, 27, 63); font-size: x-large;">שם קופאי: {{ name }}</span>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <PayDialog v-model="payDialog" v-if="payDialog" :amountToPay="amountToPay" :productInCart="customerCart"
            :Customer="customer" :cashierName="name" @purchased="purchasedSuccess" />

        <OldProductDialog v-model="oldProductDialog" v-if="oldProductDialog" :barcode="barcode"
            @setBarcode="setOldBarcode" />

        <AlertDialog v-model="alertDialog" v-if="alertDialog" :content="alertContent" />

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import PayDialog from '@/components/kupa/dialogs/PayDialog'
import Auth from '@/services/auth.service.js'
import OldProductDialog from '@/components/kupa/dialogs/OldProductDialog'
import AlertDialog from '@/components/kupa/dialogs/AlertDialog'

export default {
    props: {
        customerForKupa: Object,
        customerOrdersForKupa: Array
    },
    components: {
        SnackBar,
        PayDialog,
        OldProductDialog,
        AlertDialog
    },
    data() {
        return {
            progressShow: false,
            alertDialog: false,
            payDialog: false,
            alertDialog: false,
            oldProductDialog: false,
            alertContent: "",
            alertContentObj: null,
            barcode: "",
            customer: {},
            customerPreOrdersForShow: [],
            customerPreOrders: [],
            customerCart: [],
            allProducts: [],
            tempBothGendersInCart: [],
            tempBothTypesInCart: [],
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            name: "",
            cartHeaders: [
                { text: '#', value: "index", sortable: false },
                { text: 'ברקוד', value: 'barcode', sortable: false },
                { text: 'בן/בת', value: 'gender', sortable: false },
                { text: 'סוג', value: 'type', sortable: false },
                { text: 'מידה', value: 'size', sortable: false },
                { text: 'מחיר', value: 'price', sortable: false },
                { text: '', value: 'actions', sortable: false },
            ],
            orderedHeaders: [
                { text: '#', value: 'index', sortable: false },
                { text: 'מידה', value: 'size' },
                { text: 'בן/בת', value: 'gender' },
                { text: 'סוג', value: 'type' },
                { text: 'סטטוס', value: 'status' },
            ],
        };
    },

    computed: {
        productCountPermit() {
            return this.customer.order_details.ordered_quantity - this.customer.order_details.purchased_quantity - this.customerCart.length;
        },
        amountToPay() {
            return this.customerCart.reduce((total, item) => {
                return total + Number(item.price);
            }, 0); // Start the total at 0
        }
    },
    methods: {
        async getAllProducts() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token")
                let api = process.env.VUE_APP_BASE_URL + "/kupa/get_all_products";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400 || res.status === 404) {
                    this.showSnackBar("שגיאה בבדיקת הנתונים", "red");
                } else if (res.status === 200) {
                    this.allProducts = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get products: " + error, "red");
            }
        },
        isValidBarcode(barcode) {
            return String(barcode).length === 8;
        },
        showAlert(message) {
            this.alertContent = message;
            this.alertDialog = true;
        },
        addProductToCart() {
            const product = this.allProducts.find(product => product.barcode === this.barcode);

            if (!this.isValidBarcode(this.barcode)) {
                this.showAlert("ברקוד לא תקין, חייב להכיל 8 ספרות בדיוק");
                return;
            }

            // Get the current year's last two digits
            const currentYearLastTwo = new Date().getFullYear().toString().slice(-2);
            // Add the new condition to your existing if statement
            if (!product &&
                !this.barcode.startsWith("9100") &&
                !this.barcode.startsWith("9200") &&
                !this.barcode.startsWith("9300") &&
                !this.barcode.startsWith("9400") &&
                this.barcode.substring(4, 6) !== currentYearLastTwo) {
                this.oldProductDialog = true;
                return;
            }

            if (!product) {
                this.showAlert("הפריט המבוקש לא נמצא, נא לבדוק את תקינות הברקוד");
                return;
            }

            if (this.productCountPermit <= 0) {
                this.showAlert("הלקוח אינו זכאי לפריט נוסף");
                return;
            }

            let filterdPreOrders = [];
            filterdPreOrders = this.customerPreOrders.filter(preOrder => preOrder.status !== "paid")

/*
            // filter size
            // check all sizes can be purchased grouped by size include minus 1 and plus 1
            let groupedSizePermit = this.customerOrdersForKupa.reduce((accumulator, currentProduct) => {
                if (currentProduct.status !== "paid") {
                    const baseSize = Number(currentProduct.size);
                    const sizesToConsider = [baseSize, baseSize + 1, baseSize - 1];

                    sizesToConsider.forEach(size => {
                        // Create a unique key for each combination of size, type, and gender
                        const key = `${size}_${currentProduct.type}_${currentProduct.gender}`;

                        // Initialize the count for this key if it doesn't already exist
                        if (!accumulator[key]) {
                            accumulator[key] = 0;
                        }

                        // Increment the count for this combination
                        accumulator[key] += 1;
                    });
                }
                return accumulator;
            }, {});

            // now to get the product formatted
            let stringProduct1;
            let stringProduct2;
            let stringProduct3;
            let stringProduct4;
            if (product.gender === "בן ובת" && product.type === "שבת וחול") {
                stringProduct1 = `${product.size}_שבת_בן`;
                stringProduct2 = `${product.size}_חול_בן`;
                stringProduct3 = `${product.size}_שבת_בת`;
                stringProduct4 = `${product.size}_חול_בת`;
            } else if (product.gender === "בן ובת" && product.type === "שבת") {
                stringProduct1 = `${product.size}_שבת_בן`;
                stringProduct2 = null;
                stringProduct3 = `${product.size}_שבת_בת`;
                stringProduct4 = null;
            } else if (product.gender === "בן ובת" && product.type === "חול") {
                stringProduct1 = null;
                stringProduct2 = `${product.size}_חול_בן`;
                stringProduct3 = null;
                stringProduct4 = `${product.size}_חול_בת`;
            } else if (product.gender === "בן" && product.type === "שבת וחול") {
                stringProduct1 = `${product.size}_שבת_בן`;
                stringProduct2 = `${product.size}_חול_בן`;
                stringProduct3 = null;
                stringProduct4 = null;
            } else if (product.gender === "בת" && product.type === "שבת וחול") {
                stringProduct1 = null;
                stringProduct2 = null;
                stringProduct3 = `${product.size}_שבת_בת`;
                stringProduct4 = `${product.size}_חול_בת`;
            } else if (product.gender === "בן" && product.type === "שבת") {
                stringProduct1 = `${product.size}_שבת_בן`;
                stringProduct2 = null;
                stringProduct3 = null;
                stringProduct4 = null;
            } else if (product.gender === "בן" && product.type === "חול") {
                stringProduct1 = null;
                stringProduct2 = `${product.size}_חול_בן`;
                stringProduct3 = null;
                stringProduct4 = null;
            } else if (product.gender === "בת" && product.type === "שבת") {
                stringProduct1 = null;
                stringProduct2 = null;
                stringProduct3 = `${product.size}_שבת_בת`;
                stringProduct4 = null;
            } else if (product.gender === "בת" && product.type === "חול") {
                stringProduct1 = null;
                stringProduct2 = null;
                stringProduct3 = null;
                stringProduct4 = `${product.size}_חול_בת`;
            }

            const cartCurrentProductsUsed1 = this.customerCart.filter(orderInCart => {
                const orderSize = orderInCart.size; // Example translation
                const orderType = orderInCart.type; // Example translation
                const orderGender = orderInCart.gender; // Example translation
                // Construct the string from orderInCart properties
                const orderString = `${orderSize}_${orderType}_${orderGender}`;
                console.log(6666666);
                console.log(orderString);
                console.log(stringProduct1);
                console.log(orderString === stringProduct1);
                // Check if this constructed string matches any of your stringProduct variables
                return [stringProduct1].includes(orderString);
            });
            const cartCurrentProductsUsed2 = this.customerCart.filter(orderInCart => {
                const orderSize = orderInCart.size; // Example translation
                const orderType = orderInCart.type; // Example translation
                const orderGender = orderInCart.gender; // Example translation
                // Construct the string from orderInCart properties
                const orderString = `${orderSize}_${orderType}_${orderGender}`;
                console.log(77777);
                console.log(orderString);
                console.log(stringProduct2);
                console.log(orderString === stringProduct2);
                // Check if this constructed string matches any of your stringProduct variables
                return [stringProduct2].includes(orderString);
            });
            const cartCurrentProductsUsed3 = this.customerCart.filter(orderInCart => {
                const orderSize = orderInCart.size; // Example translation
                const orderType = orderInCart.type; // Example translation
                const orderGender = orderInCart.gender; // Example translation
                // Construct the string from orderInCart properties
                const orderString = `${orderSize}_${orderType}_${orderGender}`;
                console.log(88888);
                console.log(orderString);
                console.log(stringProduct3);
                console.log(orderString === stringProduct3);
                // Check if this constructed string matches any of your stringProduct variables
                return [stringProduct3].includes(orderString);
            });
            const cartCurrentProductsUsed4 = this.customerCart.filter(orderInCart => {
                const orderSize = orderInCart.size; // Example translation
                const orderType = orderInCart.type; // Example translation
                const orderGender = orderInCart.gender; // Example translation
                // Construct the string from orderInCart properties
                const orderString = `${orderSize}_${orderType}_${orderGender}`;
                console.log(99999);
                console.log(orderString);
                console.log(stringProduct4);
                console.log(orderString === stringProduct4);
                // Check if this constructed string matches any of your stringProduct variables
                return [stringProduct4].includes(orderString);
            });


            console.log(groupedSizePermit);
            if (this.customerCart[0] && (cartCurrentProductsUsed1[0] || cartCurrentProductsUsed2[0] || cartCurrentProductsUsed3[0] || cartCurrentProductsUsed4[0])) {
                console.log(7777888888);
                console.log(cartCurrentProductsUsed1.length);
                console.log(groupedSizePermit[stringProduct1]);

                if (cartCurrentProductsUsed1[0] && cartCurrentProductsUsed1.length < groupedSizePermit[stringProduct1]) {
                    console.log("ok");
                    filterdPreOrders = cartCurrentProductsUsed1;
                } else if (cartCurrentProductsUsed2[0] && cartCurrentProductsUsed2.length < groupedSizePermit[stringProduct2]) {
                    filterdPreOrders = cartCurrentProductsUsed2;
                } else if (cartCurrentProductsUsed3[0] && cartCurrentProductsUsed3.length < groupedSizePermit[stringProduct3]) {
                    filterdPreOrders = cartCurrentProductsUsed3;
                } else if (cartCurrentProductsUsed4[0] && cartCurrentProductsUsed4.length < groupedSizePermit[stringProduct4]) {
                    filterdPreOrders = cartCurrentProductsUsed4;
                } else {
                    filterdPreOrders = [];
                    this.alertContent = `הלקוח אינו זכאי למוצר זה: '${product.gender + " " + product.type + " " + product.size}', במידה ומופיע לו ברשימה זכאות למוצר זה, יש לבדוק אם יש בעייה באחת המוצרים הקודמים שהעביר`;
                    this.alertContentObj = groupedSizePermit;
                    this.alertDialog = true
                    return;
                }
            } else {
                console.log(9999888888);
                console.log(groupedSizePermit[stringProduct1]);
                console.log(groupedSizePermit[stringProduct2]);
                console.log(groupedSizePermit[stringProduct3]);
                console.log(groupedSizePermit[stringProduct4])
                console.log(stringProduct1);
                console.log(stringProduct2);
                console.log(stringProduct3);
                console.log(stringProduct4);
                if ((groupedSizePermit[stringProduct1] && groupedSizePermit[stringProduct1] > 0) || (groupedSizePermit[stringProduct2] && groupedSizePermit[stringProduct2] > 0) ||
                    (groupedSizePermit[stringProduct3] && groupedSizePermit[stringProduct3] > 0) || (groupedSizePermit[stringProduct4] && groupedSizePermit[stringProduct4] > 0)) {
                    console.log(9999999999999999);
                    filterdPreOrders = filterdPreOrders.filter(order => {
                        const orderSize = parseInt(order.size, 10); // Assuming 'size' is numeric and parsing it to integer
                        const orderType = order.type;
                        const orderGender = order.gender;

                        // Function to generate potential matches considering size variance
                        const generatePotentialMatches = (size) => [
                            `${size}_${orderType}_${orderGender}`,
                            `${size + 1}_${orderType}_${orderGender}`,
                            `${size - 1}_${orderType}_${orderGender}`
                        ];

                        // Generate all potential matches for the order
                        const potentialOrderMatches = generatePotentialMatches(orderSize);

                        // Check if any of the potential order matches are included in your stringProduct variables
                        return [stringProduct1, stringProduct2, stringProduct3, stringProduct4].some(productString => potentialOrderMatches.includes(productString));
                    });
                } else {
                    filterdPreOrders = [];
                    this.alertContent = `הלקוח אינו זכאי למוצר זה: '${product.gender + " " + product.type + " " + product.size}', במידה ומופיע לו ברשימה זכאות למוצר זה, יש לבדוק אם יש בעייה באחת המוצרים הקודמים שהעביר`;
                    this.alertContentObj = groupedSizePermit;
                    this.alertDialog = true
                    return;
                }
            }

            console.log(filterdPreOrders);
            console.log(333333);
*/

            if (filterdPreOrders[0]) {
                const productCopy = JSON.parse(JSON.stringify(product)); // Create a deep copy of the product
                productCopy.orderId = filterdPreOrders[0]._id;
                this.customerPreOrders.forEach(preOrder => {
                    if (preOrder._id == productCopy.orderId) {
                        preOrder.status = "paid";
                    }
                })

                this.barcode = "";
                console.log(222222);
                console.log(productCopy);
                this.customerCart.push(productCopy);
            } else {
                this.alertContent = `אחד מן הפריטים הקודמים שנוספו אינו תואם לסוג `;
                // this.alertContentObj = groupedSizePermit;
                this.alertDialog = true
            }

        },
        removeFromCart(item, index) {
            this.customerPreOrders.forEach(preOrder => {
                if (preOrder._id == item.orderId) {
                    preOrder.status = "order_manually";
                }
            })
            this.customerCart.splice(index, 1);
            if (item.gender === "בן ובת") {
                this.tempBothGendersInCart.shift();
            }
            if (item.type === "שבת וחול") {
                this.tempBothTypesInCart.shift();
            }
        },
        async purchasedSuccess() {
            try {
                let token = localStorage.getItem("token");
                let MyJSON = JSON.stringify({ card_id: this.customer._id, status: "came out" });
                let api = process.env.VUE_APP_BASE_URL + "/knisa/update_arrival_status";
                const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
            } catch (error) {
                console.log(error);
            }
            this.$emit('backToKupa1')
        },
        setOldBarcode(formatedBarcode) {
            this.barcode = formatedBarcode;
            this.$nextTick(() => {
                this.$refs.barcodeInput.focus();
            });
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        handleKeydown(event) {
            // Allow Enter key even if barcode length is 8
            if (event.key !== "Enter" && this.barcode.length >= 8) {
                event.preventDefault(); // Prevents further input
            } else if (event.key === "Enter") {
                this.addProductToCart();
            }
        },
        backToKupa1() {
            this.$emit('backToKupa1')
        },
    },
    mounted() {
        this.name = Auth.getUserName();
        this.customer = JSON.parse(JSON.stringify(this.customerForKupa));
        this.customerPreOrders = JSON.parse(JSON.stringify(this.customerOrdersForKupa));
        this.getAllProducts();
        this.customerPreOrdersForShow = JSON.parse(JSON.stringify(this.customerOrdersForKupa));
        this.customerPreOrdersForShow = this.customerPreOrdersForShow.map((order) => {
            return {
                ...order,
                status: order.status === "order_by_phone" ? "עדיין לא שולם" : order.status === "order_manually" ? "עדיין לא שולם" : "נרכש",
            }
        });
    },
};
</script>

<style scoped>
.v-dialog .v-card {
    background-color: #f4e1f7;
    /* Light purple background */
}

.v-dialog .v-card-title {
    color: #4a148c;
    /* Dark purple text */
}

.v-dialog .v-btn {
    color: #4a148c;
    /* Dark purple text for buttons */
}

.border-text-field {
    border: 1px solid #eee;
}

.divider2 {
    border-top: 0.5px solid #cfcccc;
}
</style>