<template>
  <v-container>
    <div style="margin-top: 70px; margin-bottom: 70px;">
      <v-row class="d-flex justify-center">
        <v-col cols='12' lg='10'>
          <v-card style="padding: 1%;">
            <div style="text-align: center; margin-bottom: 40px;">
              <span class="headline">דוח הכנסות</span>
            </div>
            <v-row style="margin-bottom: 30px; margin-right: 20px;">
              <v-col cols="12" sm="6" md="4">
                <v-select :items="dates" :item-text="'date'" :item-value="'date'" hide-details v-model="selectedDate"
                  outlined dense label="בחר תאריך מכירה">
                </v-select>
              </v-col>
            </v-row>

            <div style="margin-bottom: 30px; margin-right: 20px;" v-if="selectedDate">
              <v-row v-if="progressShow" class="my-5">
                <v-col class="d-flex justify-center">
                  <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="3" v-for="(item, index) in detailsFormatted"
                  style="border: 1px solid #3f51b53f; margin: 10px;">
                  <div class="d-flex justify-center">
                    <span style="font-weight: 600; font-size: large; margin-bottom: 10px;">נציג קופה: {{ item.name }}
                    </span>
                  </div>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">מזומן: </span>
                      <span style="font-size: large;">{{ item.cash_count + " ₪" }}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">אשראי: </span>
                      <span style="font-size: large;">{{ item.credit_card_count + " ₪" }}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">צ'ק: </span>
                      <span style="font-size: large;">{{ item.check_count + " ₪" }}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">יתרה: </span>
                      <span style="font-size: large;">{{ item.balance_count + " ₪" }}</span>
                    </v-col>
                  </v-row>
                  <div style="margin-top: 20px;" class="d-flex justify-center">
                    <span style="font-weight: 600; font-size: large;">סה"כ: {{ item.balance_count + item.check_count +
                      item.credit_card_count + item.cash_count + " ₪" }}</span>
                  </div>
                </v-col>
              </v-row>

              <v-row class="d-flex justify-center">
                <v-col cols="12" md="4" style="border: 1px solid #3f51b53f; margin: 30px;">
                  <div class="d-flex justify-center" style="align-items: center;">
                    <span style="font-weight: 600; font-size: x-large; margin-bottom: 10px; margin-left: 20px;">סך כל
                      ההכנסות ליום זה
                    </span>
                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" dark @click="downloadExcel()">
                            mdi-file-download
                          </v-icon>
                        </template>
                        <span>הורד לקובץ אקסל</span>
                      </v-tooltip>
                    </v-btn>
                  </div>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">מזומן: </span>
                      <span style="font-size: large;">{{ allSum.totalCash + " ₪" }}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">אשראי: </span>
                      <span style="font-size: large;">{{ allSum.totalCreditCard + " ₪" }}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">צ'ק: </span>
                      <span style="font-size: large;">{{ allSum.totalCheck + " ₪" }}</span>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <span style="font-size: large; color: #808184;">יתרה: </span>
                      <span style="font-size: large;">{{ allSum.totalBalance + " ₪" }}</span>
                    </v-col>
                  </v-row>
                  <div style="margin-top: 20px;" class="d-flex justify-center">
                    <span style="font-weight: 600; font-size: large;">סה"כ: {{ allSum.totalCash + allSum.totalCreditCard +
                      allSum.totalCheck + allSum.totalBalance + " ₪" }}</span>
                  </div>
                </v-col>
              </v-row>

            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import Utils from '@/util/utils.js'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
  components: {
    SnackBar,
  },
  data() {
    return {
      progressShow: false,
      details: [],
      detailsFormatted: [],
      allSum: {
        totalCash: 0,
        totalBalance: 0,
        totalCreditCard: 0,
        totalCheck: 0,
      },
      dates: [],
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      selectedDate: "",
    };
  },
  methods: {
    async getDates() {
      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + "/settings/get_dates";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400 || res.status === 404) {
          this.showSnackBar("תאריך לא נמצא", "red");
        } else if (res.status === 200) {
          this.dates = jsonObject;
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error check id: " + error, "red");
      }
    },
    async getDetails() {
      try {
        this.details = [];
        this.detailsFormatted = [];
        this.allSum = {
          totalCash: 0,
          totalBalance: 0,
          totalCreditCard: 0,
          totalCheck: 0,
        };
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let MyJSON = JSON.stringify({ date: this.selectedDate });
        let api = process.env.VUE_APP_BASE_URL + "/reports/get_reports_3";
        const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400 || res.status === 404) {
          this.showSnackBar("תאריך לא נמצא", "red");
        } else if (res.status === 200) {
          this.details = jsonObject;
          console.log(this.details);
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get details: " + error, "red");
      }
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    formatDetails() {
      this.details.forEach(group => {
        let cashierName = group._id; // The grouped field, _id, represents the cashier_name
        let cashCount = 0, checkCount = 0, creditCardCount = 0, balanceCount = 0;

        // Iterate over each transaction in the details array to calculate sums
        group.details.forEach(transaction => {
          transaction.pay_type.forEach(transactionType => {

            switch (transactionType.type) {
              case 'cash':
                cashCount += transactionType.amount;
                this.allSum.totalCash += transactionType.amount;
                break;
              case 'check':
                checkCount += transactionType.amount;
                this.allSum.totalCheck += transactionType.amount;
                break;
              case 'creditCard':
                creditCardCount += transactionType.amount;
                this.allSum.totalCreditCard += transactionType.amount;
                break;
              case 'balance':
                balanceCount += transactionType.amount;
                this.allSum.totalBalance += transactionType.amount;
                break;
              // Add additional cases as needed for other pay_types
            }
          });
        });
        // Push the calculated sums into detailsFormatted as an object
        this.detailsFormatted.push({
          name: cashierName,
          cash_count: cashCount,
          check_count: checkCount,
          credit_card_count: creditCardCount,
          balance_count: balanceCount
        });
      });
    },
    downloadExcel() {
      let fullDetails = [];
      this.details.forEach(group => {
        fullDetails = fullDetails.concat(group.details); // Assign the result back to fullDetails
      });

      // Preprocess fullDetails to remove specific fields and convert arrays to strings
      const processedDetails = fullDetails.map(item => {
        const { _id, __v, createdAt, updatedAt, pay_type, products, ...rest } = item;
        return {
          ...rest,
          products: products.join(", "), // Assuming pay_type is an array, adjust if necessary
          pay_type: pay_type.map(p => `Type: ${p.type}, Amount: ${p.amount}, lastDigits: ${p.last_digits}`).join("; "),
          createdAt: Utils.getCurrentDate(createdAt)
        };
      });

      // Create a new workbook and a worksheet
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(processedDetails);
      XLSX.utils.book_append_sheet(wb, ws, 'Items');

      // Generate an XLSX file
      XLSX.writeFile(wb, `הכנסות תאריך-${this.selectedDate}.xlsx`);
    }
  },
  mounted() {
    this.getDates();
  },
  watch: {
    selectedDate(newVal, oldVal) {
      this.getDetails();
    }
  },
  computed: {
  },
};
</script>
  
<style scoped></style>
  