<template>
    <div>
        <span>בס"ד</span>
        <div style="text-align: center;">
            <div style="text-align: center; margin-bottom: 20px;">
                <img src="@/assets/icon_membership.png" style="max-width: 100%;">
            </div>
            <h3>העתק חשבונית</h3>
            <h3>חלוקת נעליים ערב פסח תשפ"ד</h3>
        </div>
        <div>
            <span>תאריך הדפסה: {{ date }}</span>
        </div>
        <div>
            <span>תחנה: {{ customer.order_details.shop_station }}</span>
        </div>
        <div style="text-align: center">
            <span>-----------------------</span>
        </div>
        <div style="margin-top: 10px;">
            <div>
                <span style="font-weight: 600;">פרטי לקוח:</span>
            </div>
            <div>
                <span>שם: {{ customer.family }}</span>
            </div>
            <div>
                <span>כתובת: {{ customer.adrress }} {{ customer.city }}</span>
            </div>
            <div>
                <span>טלפון: {{ customer.phone1 }}</span>
            </div>
            <div>
                <span>ת.ז: {{ customer.father_id }}</span>
            </div>
        </div>
        <div style="text-align: center;">
            <span>-----------------------</span>
        </div>
        <div>
            <span style="font-weight: 600;">פריטים שנרכשו:</span>
        </div>
        <div style="text-align: center; margin-top: 10px;">
            <table class="table table-bordered table-hover">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col" style="margin-left: 10px;">#</th>
                        <th scope="col" style="margin-left: 20px;">ברקוד</th>
                        <th scope="col">מחיר</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in productInCart" :key="item.barcode">
                        <td style="border-left: 1px solid #d8d5d5;">{{ index + 1 + ". " }}</td>
                        <td style="margin-left: 20px; border-left: 1px solid #d8d5d5;">{{ item.barcode }}</td>
                        <td style="margin-right: 20px;">{{ item.price + " " }} ₪</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style="text-align: center; margin-top: 20px;">
            <span>-----------------------</span>
        </div>
        <div style="text-align: center;">
            <span style="font-weight: 600; margin-bottom: 10px;">חג פסח כשר ושמח</span>
        </div>
        <div style="text-align: center;">
            <span style="font-weight: 600; margin-bottom: 30px;">נוצט דאס געזונטערהייט</span>
        </div>
        <div style="margin-top: 20px;">
            <span>להערות ניתן לפנות למס 025091817 </span>
        </div>
        <div style="margin-bottom: 20px;">
            <span>בין השעות 11-3</span>
        </div>
        <div style="height: 10px; text-align: center;">
        </div>
    </div>
</template>
    
<script>
import Utils from "@/util/utils";

export default {
    props: {
        customer: Object,
        productInCart: Array,
    },
    data() {
        return {
            cartProduct: [],
            date: "",
        };
    },
    created() {
        this.cartProduct = [this.productInCart];
        this.date = Utils.getFormatDate2(new Date);
    },
    watch: {
    },
    methods: {
    }
}
</script>
<style ></style>    