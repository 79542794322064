<template>
    <div style="margin-top: 50px;">
        <v-row style="margin-bottom: 20px;">
            <v-col cols="12" class="d-flex justify-center">
                <v-card style="padding: 10px; border: 1px solid #3f51b53f;">
                    <div>
                        <span style="font-weight: 600; font-size: x-large; margin-left: 10px;">סך כל
                            ההזמנות:
                            {{ countOrders.allCitiesTotalCount }} | </span>
                        <span style="font-weight: 600; font-size: x-large; margin-left: 20px;">כמות לקוחות שהזמינו:
                            {{ customersCount }}</span>
                        <v-btn @click="refreshDetail" icon>
                            <v-icon color="primary">
                                mdi-refresh
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="progressShow" class="my-5">
            <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
            <v-col cols="auto" sm="5" v-for="(item, index) in stationTablesArray">
                <v-card style="padding: 20px; margin: 20px; border: 1px solid #3f51b53f;">
                    <div class="pt-1 d-flex justify-content-between"
                        style="background-color: rgba(0, 0, 255, 0.61); border-radius: 15px 15px 0px 0px;">
                        <div class="text-center flex-grow-1">
                            <span style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">
                                הזמנות תחנה {{ item.cityName }}
                            </span>
                        </div>
                    </div>
                    <div class="divider2"></div>
                    <div style="height: 250px; overflow-y: auto; background-color: white; border-radius: 15px;">
                        <v-data-table :id="'table' + (index + 1)" :headers="ordersHeaders" :items="item.items"
                            sort-by="index" class="elevation-0" hide-default-footer :items-per-page="item.items.length">
                            <template v-slot:no-data>
                                <v-row v-if="progressShow" class="my-5">
                                    <v-col class="d-flex justify-center">
                                        <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                                    </v-col>
                                </v-row>
                                <div class="ma-5">
                                    <span class="text-center"
                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                                        לא נמצאו הזמנות</span>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                    <div class="divider2"></div>
                    <v-row style="margin-top: 20px; border-top: 1px solid #bdbcbc;">
                        <v-col cols='12' sm='5' style="margin-left: 5%; border-bottom: 1px solid #cfcdcd;">
                            <div class="text-center">
                                <div>
                                    <span style="font-weight: 600;">סה"כ נעליים לתחנה {{ item.cityName }}</span>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid #eee; margin-bottom: 5px;"></div>
                            <v-row class="text-center">
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ item.countCityOrders }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" dark @click="downloadExcel(index + 1, item.ordersXlName)">
                                                    mdi-file-download
                                                </v-icon>
                                            </template>
                                            <span>הורד לקובץ אקסל</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols='12' sm='5' style="border-bottom: 1px solid #cfcdcd;">
                            <div class="text-center">
                                <div>
                                    <span style="font-weight: 600;">סה"כ לקוחות לתחנה {{ item.cityName }}</span>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid #eee; margin-bottom: 5px;"></div>
                            <v-row class="text-center">
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ item.countCityCustomers.length }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" dark
                                                    @click="exportToExcel('לקוחות לתחנה', item.cityName)">
                                                    mdi-file-download
                                                </v-icon>
                                            </template>
                                            <span>הורד לקובץ אקסל</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row style="margin-top: 20px;">
                        <v-col cols='12' sm='8' style="margin-left: 5%; border-bottom: 1px solid #eee;">
                            <div class="text-center">
                                <div>
                                    <span style="font-weight: 600;">סה"כ לקוחות שלא קבעו תור לתחנה {{ item.cityName
                                    }}</span>
                                </div>
                            </div>
                            <div style="border-bottom: 1px solid #eee; margin-bottom: 5px;"></div>
                            <v-row class="text-center">
                                <v-col cols="12" sm="6">
                                    <div>
                                        <span class="text-span">{{ item.countCityCustomersNoAppointment.length }}</span>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-btn small icon color="#3F51B5" dark style="max-width: 20px;">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-icon v-on="on" dark
                                                    @click="exportToExcel('לקוחות בלי תור לתחנה', item.cityName)">
                                                    mdi-file-download
                                                </v-icon>
                                            </template>
                                            <span>הורד לקובץ אקסל</span>
                                        </v-tooltip>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    name: 'agent',
    components: {
        SnackBar
    },
    data: () => ({
        ordersHeaders: [
            { text: 'מידה', value: 'size' },
            { text: 'בן/בת', value: 'gender' },
            { text: 'סוג', value: 'type' },
            { text: 'כמות הזמנות', value: 'totalQuantity' },
        ],
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        citiesOrders: {
            jerusalemSummary: [],
            beitShemeshSummary: [],
            bneiBrakSummary: [],
            modiinIllitSummary: [],
        },
        customersCount: 0,
        customersCountPerStation: {
            'ירושלים': [],
            'בית שמש': [],
            'בני ברק': [],
            'מודיעין עילית': [],
        },
        countOrders: {
            jerusalemTotalCount: 0,
            beitShemeshTotalCount: 0,
            bneiBrakTotalCount: 0,
            modiinIllitTotalCount: 0,
            allCitiesTotalCount: 0,
        },
        countCustomersNoAppoint: {
            jerusalemTotalCount: [],
            beitShemeshTotalCount: [],
            bneiBrakTotalCount: [],
            modiinIllitTotalCount: [],
        },
    }),
    computed: {
        stationTablesArray() {
            return [
                {
                    items: this.citiesOrders.jerusalemSummary,
                    ordersXlName: 'הזמנות_ירושלים',
                    cityName: 'ירושלים',
                    countCityOrders: this.countOrders.jerusalemTotalCount,
                    countCityCustomers: this.customersCountPerStation['ירושלים'],
                    countCityCustomersNoAppointment: this.countCustomersNoAppoint.jerusalemTotalCount,
                },
                {
                    items: this.citiesOrders.beitShemeshSummary,
                    ordersXlName: 'הזמנות_בית_שמש',
                    cityName: 'בית שמש',
                    countCityOrders: this.countOrders.beitShemeshTotalCount,
                    countCityCustomers: this.customersCountPerStation['בית שמש'],
                    countCityCustomersNoAppointment: this.countCustomersNoAppoint.beitShemeshTotalCount,
                },
                {
                    items: this.citiesOrders.bneiBrakSummary,
                    ordersXlName: 'הזמנות_בני_ברק',
                    cityName: 'בני ברק',
                    countCityOrders: this.countOrders.bneiBrakTotalCount,
                    countCityCustomers: this.customersCountPerStation['בני ברק'],
                    countCityCustomersNoAppointment: this.countCustomersNoAppoint.bneiBrakTotalCount,
                },
                {
                    items: this.citiesOrders.modiinIllitSummary,
                    ordersXlName: 'הזמנות_מודיעין_עילית',
                    cityName: 'מודיעין עילית',
                    countCityOrders: this.countOrders.modiinIllitTotalCount,
                    countCityCustomers: this.customersCountPerStation['מודיעין עילית'],
                    countCityCustomersNoAppointment: this.countCustomersNoAppoint.modiinIllitTotalCount,
                },
            ]
        }
    },
    methods: {
        async getFirstReports() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/reports/get_reports_1";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.citiesOrders = jsonObject.citiesOrdersSummaries;
                    this.customersCount = jsonObject.totalUniqueCustomerCount;
                    this.getCountPerCity();

                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get first report: " + error, "red");
            }
        },
        async getOtherDetails() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/reports/get_other_details";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.countCustomersNoAppoint.jerusalemTotalCount = jsonObject.customersNoAppointment.filter(customer => customer.shop_station === "ירושלים");
                    this.countCustomersNoAppoint.beitShemeshTotalCount = jsonObject.customersNoAppointment.filter(customer => customer.shop_station === "בית שמש");
                    this.countCustomersNoAppoint.bneiBrakTotalCount = jsonObject.customersNoAppointment.filter(customer => customer.shop_station === "בני ברק");
                    this.countCustomersNoAppoint.modiinIllitTotalCount = jsonObject.customersNoAppointment.filter(customer => customer.shop_station === "מודיעין עילית");
                    this.customersCountPerStation = jsonObject.customerCountPerStation;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get other details: " + error, "red");
            }
        },
        async refreshDetail() {
            this.getFirstReports();
            this.getOtherDetails();
        },
        downloadExcel(tableNum, cityName) {
            console.log(tableNum);
            const table = document.getElementById(`table${tableNum}`);
            const clonedTable = table.cloneNode(true);

            TableToExcel.convert(clonedTable, {
                name: `${cityName}.xlsx`,
                sheet: {
                    name: 'Sheet 1',
                },
            });
        },
        getCountPerCity() {
            this.countOrders = {
                jerusalemTotalCount: 0,
                beitShemeshTotalCount: 0,
                bneiBrakTotalCount: 0,
                modiinIllitTotalCount: 0,
                allCitiesTotalCount: 0,
            };
            this.citiesOrders.jerusalemSummary.forEach(element => {
                this.countOrders.jerusalemTotalCount += element.totalQuantity;
            });
            this.citiesOrders.beitShemeshSummary.forEach(element => {
                this.countOrders.beitShemeshTotalCount += element.totalQuantity;
            });
            this.citiesOrders.bneiBrakSummary.forEach(element => {
                this.countOrders.bneiBrakTotalCount += element.totalQuantity;
            });
            this.citiesOrders.modiinIllitSummary.forEach(element => {
                this.countOrders.modiinIllitTotalCount += element.totalQuantity;
            });
            this.countOrders.allCitiesTotalCount = this.countOrders.jerusalemTotalCount + this.countOrders.beitShemeshTotalCount +
                this.countOrders.bneiBrakTotalCount + this.countOrders.modiinIllitTotalCount
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        exportToExcel(dataType, cityName) {
            let data;
            let xlsName;
            if (dataType === "לקוחות לתחנה") {
                data = this.customersCountPerStation[cityName];
                xlsName = `לקוחות_תחנה_${cityName}`;
            } else {
                let city;
                if (cityName === "ירושלים") {
                    city = 'jerusalemTotalCount'
                }else if (cityName === "בית שמש") {
                    city = 'beitShemeshTotalCount'
                }else if (cityName === "בני ברק") {
                    city = 'bneiBrakTotalCount'
                }else if (cityName === "מודיעין עילית") {
                    city = 'modiinIllitTotalCount'
                }
                data = this.countCustomersNoAppoint[city];
                xlsName = `לקוחות_בלי_תור_תחנה_${cityName}`;
            }

            // Create a new workbook and a worksheet
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(data);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, 'Items');

            // Generate an XLSX file
            XLSX.writeFile(wb, `${xlsName}.xlsx`);
        },
    },
    mounted() {
        this.getFirstReports();
        this.getOtherDetails();
    },
}
</script>
<style scoped></style>