<template>
    <v-dialog v-model="openMode" persistent max-width="700px">
        <v-card style="background-color: #eee; padding-bottom: 30px;">
            <div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="#3F51B5" large @click="openMode = false">
                            mdi-window-close
                        </v-icon>
                    </template>
                    <span>חזור לתשלום</span>
                </v-tooltip>
            </div>

            <div
                style="width:60%;max-width:100%;border:2px solid cadetblue;padding:20px;margin-right:auto;margin-left:auto; background-color: white">
                <div style="text-align:center">
                    <h3>הזנת פרטי צ'ק</h3>
                </div>

                <span class="d-flex justify-center" style="color: #1ba39c; font-weight: 600; margin-bottom: 20px;">סכום
                    לתשלום: {{ " " +
                        amount + ' ש"ח' }}</span>

                <v-form @submit.prevent="submitHandler" ref="form">
                    <!-- <v-text-field outlined dense v-model="userInput.check_number" type="number" hide-spin-buttons autofocus
                        :rules="[v => !!v || 'שדה חובה']" required label="מספר צ'ק"></v-text-field>

                    <v-text-field outlined dense v-model="userInput.check_date" :rules="[v => !!v || 'שדה חובה']" required
                        type="date" label="תאריך"></v-text-field>

                    <v-text-field outlined dense v-model="userInput.bank_name" :rules="[v => !!v || 'שדה חובה']" required
                        type="text" label="בנק"></v-text-field>

                    <v-text-field outlined dense v-model="userInput.bank_snif" :rules="[v => !!v || 'שדה חובה']" required
                        type="text" label="סניף"></v-text-field>

                    <v-text-field outlined dense v-model="userInput.account_number" :rules="[v => !!v || 'שדה חובה']" required
                        type="number" hide-spin-buttons label="מספר חשבון"></v-text-field> -->

                    <div class="d-flex justify-center">
                        <v-btn dark color="#17a2b8" @click="PayCheckClick">אישור</v-btn>
                    </div>
                </v-form>
            </div>

        </v-card>

    </v-dialog>
</template>
  
<script>

export default {
    props: {
        customer: Object,
        amount: Number,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        progressShow: true,
        finishText: "נא להמתין לסיום התהליך....",
        iframeSrc: "https://matara.pro/nedarimplus/iframe?language=he",
        userInput: {
            check_number: "",
            check_date: "",
            bank_name: "",
            bank_snif: "",
            account_number: "",
        },
    }),
    methods: {
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true;
        },
        closeDialog() {
            this.openMode = false;
        },
        async PayCheckClick() {
            if (this.$refs.form.validate()) {

                if (this.amount > 0) {
                    this.$emit('paySuccess', this.amount, { type: "check", amount: this.amount, check_details: this.userInput });
                }
                this.closeDialog();
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    created() {
        this.userInput = JSON.parse(JSON.stringify(this.customer));
    },
}
</script>
<style scoped>
/* This will hide the spin button in Chrome, Edge, Safari, and Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* This will hide the spin button in Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.TextBox {
    -webkit-appearance: none;
    font-family: 'Assistant', sans-serif;
    font-size: large;
    color: black;
    width: 100%;
    text-align: right;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 2px;
    box-sizing: content-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-top: 2px;
    outline: none;
}

@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGSV35Gu.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGiV35Gu.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/assistant/v18/2sDPZGJYnIjSi6H75xkZZE1I0yCmYzzQtuZnIGaV3w.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
