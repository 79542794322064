var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',[_vm._v("בס\"ד")]),_vm._m(0),_c('div',[_c('span',[_vm._v("תאריך הדפסה: "+_vm._s(_vm.payDetails.createdAt))])]),_c('div',[_c('span',[_vm._v("תחנה: "+_vm._s(_vm.customer.order_details.shop_station))])]),_vm._m(1),_c('div',{staticStyle:{"margin-top":"10px"}},[_vm._m(2),_c('div',[_c('span',[_vm._v("שם: "+_vm._s(_vm.customer.family))])]),_c('div',[_c('span',[_vm._v("כתובת: "+_vm._s(_vm.customer.adrress)+" "+_vm._s(_vm.customer.city))])]),_c('div',[_c('span',[_vm._v("טלפון: "+_vm._s(_vm.customer.phone1))])]),_c('div',[_c('span',[_vm._v("ת.ז: "+_vm._s(_vm.customer.father_id))])])]),_vm._m(3),_vm._m(4),_c('div',{staticStyle:{"text-align":"center","margin-top":"10px"}},[_c('table',{staticClass:"table table-bordered table-hover"},[_vm._m(5),_c('tbody',_vm._l((_vm.productInCart),function(item,index){return _c('tr',{key:item.barcode},[_c('td',{staticStyle:{"border-left":"1px solid #d8d5d5"}},[_vm._v(_vm._s(index + 1 + ". "))]),_c('td',{staticStyle:{"margin-left":"20px","border-left":"1px solid #d8d5d5"}},[_vm._v(_vm._s(item.barcode))]),_c('td',{staticStyle:{"margin-right":"20px"}},[_vm._v(_vm._s(item.price + " ")+" ₪")])])}),0)])]),_vm._m(6),_vm._m(7),_vm._l((_vm.payDetails.pay_type),function(item,index){return _c('div',[_c('div',[_c('span',[_vm._v(_vm._s(index + 1 + ". "))]),_c('span',[_vm._v("צורת תשלום: "+_vm._s(item.type === "balance" ? "יתרה" : item.type === "creditCard" ? "כ. אשראי" : item.type === "cash" ? "מזומן" : "צ'ק")+" "+_vm._s(" " + item.last_digits))])]),_c('div',[_c('span',[_vm._v(_vm._s("סכום: " + item.amount + " ₪"))])])])}),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('div',{staticStyle:{"height":"10px","text-align":"center"}})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticStyle:{"text-align":"center","margin-bottom":"20px"}},[_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":require("@/assets/icon_membership.png")}})]),_c('h3',[_vm._v("חשבונית רכישה")]),_c('h3',[_vm._v("חלוקת נעליים ערב פסח תשפ\"ד")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("-----------------------")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("פרטי לקוח:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("-----------------------")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("פריטים שנרכשו:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-dark"},[_c('tr',[_c('th',{staticStyle:{"margin-left":"10px"},attrs:{"scope":"col"}},[_vm._v("#")]),_c('th',{staticStyle:{"margin-left":"20px"},attrs:{"scope":"col"}},[_vm._v("ברקוד")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("מחיר")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('span',[_vm._v("-----------------------")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("פרטי תשלום:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","margin-top":"20px"}},[_c('span',[_vm._v("-----------------------")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticStyle:{"font-weight":"600","margin-bottom":"10px"}},[_vm._v("חג פסח כשר ושמח")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('span',{staticStyle:{"font-weight":"600","margin-bottom":"30px"}},[_vm._v("נוצט דאס געזונטערהייט")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"20px"}},[_c('span',[_vm._v("להערות ניתן לפנות למס 025091817 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('span',[_vm._v("בין השעות 11-3")])])
}]

export { render, staticRenderFns }