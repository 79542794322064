<template>
    <div class="center-container">
        <v-card class="center-card">
            <div class="d-flex justify-end pt-3 pl-3">
                <span class="text-center flex-grow-1"
                    style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #5C6BC0">פרטי המשתמש
                </span>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="#5C6BC0" medium class="mr-2 me-3" @click="openEditFrom()">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>עריכה</span>
                </v-tooltip>
            </div>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="6">
                            <v-card-title><span style="color: #5C6BC0">שם</span></v-card-title>
                            <v-card-text>{{ userDetails.name }}</v-card-text>
                        </v-col>
                        <v-col cols="6">

                            <v-card-title><span style="color: #5C6BC0">username</span></v-card-title>
                            <v-card-text>{{ userDetails.username }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-card-title><span style="color: #5C6BC0">פלאפון</span></v-card-title>
                            <v-card-text>{{ userDetails.phone }}</v-card-text>
                        </v-col>
                        <v-col cols="6">
                            <v-card-title><span style="color: #5C6BC0">אימייל</span></v-card-title>
                            <v-card-text>{{ userDetails.email }}</v-card-text>
                        </v-col>

                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-card-title><span style="color: #5C6BC0">הרשאות</span></v-card-title>
                            <v-card-text>{{ userDetails.role }}</v-card-text>
                        </v-col>
                        <v-col cols="6">
                            <v-card-title><span style="color: #5C6BC0">APi
                                    Token</span></v-card-title>
                            <v-card-text>{{ userDetails.api_token }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    components: {
        SnackBar
    },
    data() {
        return {
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            userDetails: {
                name: "aa",
                phone: "dd",
                email: "dd",
                role: "gg",
                username: "gg",
                api_token: "gg",
            },
            roles: [
                { hebrewName: "הכל", name: "all" },
                { hebrewName: "לקוחות", name: "customers" },
                { hebrewName: "דוחות", name: "reports" },
                { hebrewName: "מלאי", name: "products" },
                { hebrewName: "קופה", name: "cashRegister" },
                { hebrewName: "כניסה למכירה", name: "enterToShop" },
                { hebrewName: "קופה, לקוחות", name: "customersAndcashRegister" },
                { hebrewName: "הכל חוץ ממשתמשים", name: "allExceptUsers" },
            ],
        }
    },
    methods: {

        async getUserDetails() {
            try {
                this.userDetails = {};
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/account/get_user_details";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                this.progressShow = false;
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.userDetails = jsonObject;
                    this.formatDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get user details: " + error, "red");
            }
        },
        formatDetails() {
            const foundRole = this.roles.find(role => role.name === this.userDetails.role);
            const roleHebrewName = foundRole.hebrewName;
            this.userDetails.role = roleHebrewName;
        },
        openEditFrom() {
            this.$emit("Edit", this.userDetails);
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
        this.getUserDetails();
    },

};
</script>
<style>
.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    margin-right: 5px;
    margin-left: 5px;
}

.center-card {
    max-width: 900px;
    width: 100%;
    padding-bottom: 40px;
    margin-left: 5px;
    margin-right: 5px;
}
</style>