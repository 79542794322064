<template>
    <div>

        <v-card class="mx-auto" max-width="700" style="margin-top: 100px;">
            <div class="d-flex justify-end pt-3 pl-3">
                <span class="text-center flex-grow-1"
                    style="font-weight: bold; font-size: 30px; font-family: Times, serif;">
                    ערוך פרטי משתמש
                </span>
            </div>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="6">
                                <v-card-title>שם</v-card-title>
                                <v-text-field v-model="userDetailsEdited.name" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-card-title>username</v-card-title>
                                <v-text-field v-model="userDetailsEdited.username" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-card-title>סיסמה</v-card-title>
                                <v-text-field v-model="passObj.password"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-card-title>אימות סיסמה</v-card-title>
                                <v-text-field v-model="passObj.repeat_password"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-card-title>פלאפון</v-card-title>
                                <v-text-field v-model="userDetailsEdited.phone"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-card-title>אימייל</v-card-title>
                                <v-text-field v-model="userDetailsEdited.email"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>

            <v-card-actions class="d-flex justify-center">
                <div style="margin-bottom: 30px;">
                    <v-btn color="#6a6aec" text @click="cancelChanges">ביטול</v-btn>
                    <v-btn color="#6a6aec" text @click="saveChanges">שמור</v-btn>
                </div>
            </v-card-actions>
            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    components: {
        SnackBar
    },
    props: {
        userDetails: Object
    },
    data() {
        return {
            progressShow: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            userDetailsEdited: {
                name: "",
                username: "",
                phone: "",
                email: "",
            },
            passObj: {
                password: "",
                repeat_password: "",
            },
        };
    },
    methods: {
        async saveChanges() {
            if (this.$refs.form.validate()) {

                if (this.userDetailsEdited.username.length < 5) {
                    return this.showSnackBar("username חייב לכלול לפחות 5 תווים", "red");
                }

                if (this.passObj.password) {
                    if (this.passObj.password.length < 9 || this.passObj.password.length > 20) {
                        return this.showSnackBar("הסיסמה חייבת לכלול לפחות 9 תווים ולא יותר מ 20", "red");
                    }
                    if (this.passObj.password !== this.passObj.repeat_password) {
                        return this.showSnackBar("נא לאמת את הסיסמה", "red");
                    }
                }

                const editedJSON = JSON.stringify({ userDetails: this.userDetailsEdited, passObj: this.passObj });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/account/edit_user_details";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", editedJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 401 || res.status === 400) {
                        this.showSnackBar("שגיאה: " + jsonObject.message, "red");
                    }
                    if (res.status === 200) {
                        this.showSnackBar("השינויים נשמרו בהצלחה!", "green");
                        setTimeout(() => {
                            this.$emit("Cancle");
                        }, 2000);
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved details: " + error, "red");
                }
            }
        },
        cancelChanges() {
            this.$emit("Cancle");
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
        this.userDetailsEdited = this.userDetails;
    },
};
</script>
  