<template>
  <v-dialog v-model="openMode" max-width="500px">
    <v-card style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
      <v-card-title class="text-h5">בחר פעולה</v-card-title>
      <v-card-text class="d-flex justify-space-around">
        <v-card style="margin-top: 20px; margin-bottom: 20px;">
          <input type="file" style="display: none;" accept=".xls, .xlsx" ref="fileInput" @change="handleFileUpload">
          <a href="#" class="content-card" style="text-decoration: none;" @click="$refs.fileInput.click()">

            <v-card-text class="content-text">ייבוא מאקסל</v-card-text>
          </a>
        </v-card>
        <v-card style="margin-top: 20px; margin-bottom: 20px;">

          <a href="#" class="content-card" @click="downloadExapmleExcel()" style="text-decoration: none;">
            <v-card-text class="content-text decoration-none">הורד דוגמא לצורך
              ייבוא</v-card-text>
          </a>
        </v-card>
      </v-card-text>
      <v-row v-if="progressShow">
        <v-col class="d-flex justify-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-dialog>
</template>
    
<script>
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
  props: {
    value: { type: Boolean, default: false },

  },
  components: {
    SnackBar,
  },
  data: () => ({
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
  }),

  methods: {
    handleFileUpload(event) {
      const file = event.target.files[event.target.files.length - 1];
      if (file) {
        this.$emit("ImportExcel", file);
        // Reset the file input after emitting the file
        this.openMode = false;
      }
    },
    async downloadExapmleExcel() {
      this.$emit("exportExampleExcel");
      this.openMode = false;
    },

  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
}
</script>
<style></style>
      