<template>
  <div>
    <v-row>
      <v-col cols='12' xl='2'>
        <TablesList @showTable="showTable" @showAccountDetails="showAccountDetails()" :tableListArray="tableListArray" />
      </v-col>
      <v-col cols='12' xl='10'>
        <div style="margin-top: 20px;">
          <v-card
            style="width: 400px; max-width: 100%; height: 45px; padding: 5px; margin: 0 auto; border: 1px solid #3F51B5;">
            <div class="center-header">
              <span style="color: #5C6BC0!important; font-size: 20px;">מערכת ניהול - משמחי לב</span>
            </div>
          </v-card>
        </div>
        <UsersTable v-model="usersTable" v-if="usersTable" />

        <AccountDetails v-model="accountDetails" v-if="accountDetails" @Edit="editUserDetails" />
        <AccountEditDetails v-model="accountEditDetails" v-if="accountEditDetails" :userDetails="userDetailsForEdit"
          @Cancle="showAccountDetails" />

        <ProductsTable v-model="productsTable" v-if="productsTable" />

        <CustomersTable v-model="customersTable" v-if="customersTable" @openCustomerDetails="openCustomerDetails" />
        <CustomerDetails v-model="customerDetailsPage" v-if="customerDetailsPage" :customerId="customerIdForEdit"
          @backToCustomers="backToCustomers" />

        <OrdersTable v-model="ordersTable" v-if="ordersTable" @openCustomerDetails="openCustomerDetails" />

        <ExchangesComp v-model="exchangesComp" v-if="exchangesComp" />

        <KnisaComp v-model="knisaComp" v-if="knisaComp" />

        <BeforeKnisaComp v-model="beforeKnisaComp" v-if="beforeKnisaComp" />

        <KupaComp1 v-model="kupaComp1" v-if="kupaComp1" @moveToKupa2="moveToKupa2" />
        <KupaComp2 v-model="kupaComp2" v-if="kupaComp2" :customerForKupa="customerForKupa"
          :customerOrdersForKupa="customerOrdersForKupa" @backToKupa1="backToKupa1" />

        <ReportsComp v-model="reportsComp" v-if="reportsComp" />

        <SettingsComp v-model="settingsComp" v-if="settingsComp" />

        <PhoneStockComp v-model="phoneLimitStock" v-if="phoneLimitStock" />

      </v-col>
    </v-row>

  </div>
</template>
<script>
import TablesList from '@/components/TablesList'
import UsersTable from '@/components/users/UsersTable'
import ProductsTable from '@/components/products/ProductsTable'
import ReportsComp from '@/components/reports/ReportsMenuComp'
import CustomersTable from '@/components/customers/CustomersTable'
import CustomerDetails from '@/components/customers/CustomerDetails'
import OrdersTable from '@/components/orders/OrdersTable'
import ExchangesComp from '@/components/exchanges/ExchangesComp'
import KnisaComp from '@/components/knisa/KnisaComp'
import BeforeKnisaComp from '@/components/beforeKnisa/BeforeKnisaComp'
import KupaComp1 from '@/components/kupa/KupaComp1'
import KupaComp2 from '@/components/kupa/KupaComp2'
import SettingsComp from '@/components/settings/SettingsComp'
import PhoneStockComp from '@/components/phoneStock/PhoneStockComp'
import AccountDetails from '@/components/account/UserAccount'
import AccountEditDetails from '@/components/account/UserAccountEdit'
import Auth from '@/services/auth.service.js'

export default {
  name: 'home',
  components: {
    TablesList,
    UsersTable,
    AccountEditDetails,
    AccountDetails,
    ProductsTable,
    CustomersTable,
    CustomerDetails,
    OrdersTable,
    ExchangesComp,
    KnisaComp,
    KupaComp1,
    KupaComp2,
    ReportsComp,
    BeforeKnisaComp,
    SettingsComp,
    PhoneStockComp
  },
  data: () => ({
    usersTable: false,
    customerDetailsPage: false,
    accountEditDetails: false,
    accountDetails: false,
    productsTable: false,
    reportsComp: false,
    settingsComp: false,
    phoneLimitStock: false,
    ordersTable: false,
    exchangesComp: false,
    knisaComp: false,
    beforeKnisaComp: false,
    kupaComp1: false,
    kupaComp2: false,
    customersTable: false,
    userDetailsForEdit: {},
    customerIdForEdit: "",
    customerForKupa: {},
    customerOrdersForKupa: [],
    tableListArray: [
      {
        name: "משתמשים"
      },
      {
        name: "לקוחות"
      },
      {
        name: "מלאי"
      },
      {
        name: "הזמנות"
      },
      {
        name: "החלפה והחזרה"
      },
      {
        name: "דוחות"
      },
      {
        name: "קופה"
      },
      {
        name: "כניסה והדפסה"
      },
      {
        name: "לפני כניסה"
      },
      {
        name: "הגדרות שעות חלוקה"
      },
      {
        name: "מלאי טלפוני"
      },
    ],
  }),
  methods: {
    showTable(name) {
      if (name == 'משתמשים') {
        this.usersTable = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.accountDetails = false;
        this.ordersTable = false;
        this.productsTable = false;
        this.customerDetailsPage = false;
        this.customersTable = false;
        this.accountEditDetails = false;
        this.knisaComp = false;
        this.beforeKnisaComp = false;
        this.reportsComp = false;
      } else if (name == 'מלאי') {
        this.productsTable = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.customersTable = false;
        this.ordersTable = false;
        this.customerDetailsPage = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.accountEditDetails = false;
        this.knisaComp = false;
        this.beforeKnisaComp = false;
        this.reportsComp = false;
      } else if (name == 'לקוחות') {
        this.customersTable = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.productsTable = false;
        this.usersTable = false;
        this.ordersTable = false,
          this.customerDetailsPage = false,
          this.accountDetails = false;
        this.accountEditDetails = false;
        this.knisaComp = false;
        this.beforeKnisaComp = false;
        this.reportsComp = false;
      } else if (name == 'הזמנות') {
        this.ordersTable = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.knisaComp = false;
        this.beforeKnisaComp = false;
        this.reportsComp = false;
      } else if (name == 'החלפה והחזרה') {
        this.exchangesComp = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.ordersTable = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.knisaComp = false;
        this.beforeKnisaComp = false;
        this.reportsComp = false;
      } else if (name == 'קופה') {
        this.kupaComp1 = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.kupaComp2 = false;
        this.knisaComp = false;
        this.beforeKnisaComp = false;
        this.ordersTable = false
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.reportsComp = false;
      } else if (name == 'דוחות') {
        this.reportsComp = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.knisaComp = false;
        this.beforeKnisaComp = false;
        this.ordersTable = false
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
      } else if (name == 'כניסה והדפסה') {
        this.knisaComp = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.beforeKnisaComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.ordersTable = false;
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.reportsComp = false;
      } else if (name == 'לפני כניסה') {
        this.beforeKnisaComp = true;
        this.phoneLimitStock = false;
        this.settingsComp = false;
        this.exchangesComp = false;
        this.knisaComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.ordersTable = false
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.reportsComp = false;
      } else if (name == 'הגדרות שעות חלוקה') {
        this.settingsComp = true;
        this.phoneLimitStock = false;
        this.beforeKnisaComp = false;
        this.exchangesComp = false;
        this.knisaComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.ordersTable = false
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.reportsComp = false;
      }else if (name == 'מלאי טלפוני') {
        this.phoneLimitStock = true;
        this.settingsComp = false;
        this.beforeKnisaComp = false;
        this.exchangesComp = false;
        this.knisaComp = false;
        this.kupaComp1 = false;
        this.kupaComp2 = false;
        this.ordersTable = false
        this.productsTable = false;
        this.usersTable = false;
        this.accountDetails = false;
        this.customerDetailsPage = false,
          this.accountEditDetails = false;
        this.customersTable = false;
        this.reportsComp = false;
      }
    },
    showAccountDetails() {
      this.accountDetails = true;
      this.phoneLimitStock = false;
      this.settingsComp = false;
      this.exchangesComp = false;
      this.kupaComp1 = false;
      this.kupaComp2 = false;
      this.usersTable = false;
      this.productsTable = false;
      this.customersTable = false;
      this.accountEditDetails = false;
      this.ordersTable = false;
      this.customerDetailsPage = false;
      this.knisaComp = false;
      this.beforeKnisaComp = false;
      this.reportsComp = false;
    },
    editUserDetails(user) {
      this.userDetailsForEdit = user;

      this.accountEditDetails = true;
      this.phoneLimitStock = false;
      this.settingsComp = false;
      this.exchangesComp = false;
      this.kupaComp1 = false;
      this.kupaComp2 = false;
      this.customersTable = false;
      this.usersTable = false;
      this.productsTable = false;
      this.accountDetails = false;
      this.customerDetailsPage = false;
      this.ordersTable = false;
      this.knisaComp = false;
      this.beforeKnisaComp = false;
      this.reportsComp = false;
    },
    openCustomerDetails(customerId) {
      this.customerIdForEdit = customerId;

      this.customerDetailsPage = true;
      this.phoneLimitStock = false;
      this.settingsComp = false;
      this.exchangesComp = false;
      this.kupaComp1 = false;
      this.kupaComp2 = false;
      this.customersTable = false;
      this.usersTable = false;
      this.productsTable = false;
      this.accountDetails = false;
      this.ordersTable = false;
      this.accountEditDetails = false;
      this.knisaComp = false;
      this.beforeKnisaComp = false;
      this.reportsComp = false;
    },
    backToCustomers() {
      this.customersTable = true;
      this.phoneLimitStock = false;
      this.settingsComp = false;
      this.exchangesComp = false;
      this.kupaComp1 = false;
      this.kupaComp2 = false;
      this.usersTable = false;
      this.productsTable = false;
      this.accountDetails = false;
      this.ordersTable = false;
      this.accountEditDetails = false;
      this.customerDetailsPage = false;
      this.knisaComp = false;
      this.beforeKnisaComp = false;
      this.reportsComp = false;
    },
    moveToKupa2(customer, orders) {
      this.customerForKupa = customer;
      this.customerOrdersForKupa = orders;

      this.kupaComp2 = true;
      this.phoneLimitStock = false;
      this.settingsComp = false;
      this.exchangesComp = false;
      this.kupaComp1 = false;
      this.customersTable = false;
      this.usersTable = false;
      this.productsTable = false;
      this.accountDetails = false;
      this.ordersTable = false;
      this.accountEditDetails = false;
      this.customerDetailsPage = false;
      this.knisaComp = false;
      this.beforeKnisaComp = false;
      this.reportsComp = false;
    },
    backToKupa1() {
      this.kupaComp1 = true;
      this.phoneLimitStock = false;
      this.settingsComp = false;
      this.exchangesComp = false;
      this.kupaComp2 = false;
      this.customersTable = false;
      this.usersTable = false;
      this.productsTable = false;
      this.accountDetails = false;
      this.ordersTable = false;
      this.accountEditDetails = false;
      this.customerDetailsPage = false;
      this.knisaComp = false;
      this.beforeKnisaComp = false;
      this.reportsComp = false;
    },
  },
  created() {
    let auth = Auth.checkAuth();
    let role = Auth.getUserRole();
    if (role === "all") {
      this.usersTable = true;
    } else if (role === "customers") {
      this.customersTable = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "לקוחות")
    } else if (role === "reports") {
      this.reportsComp = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "דוחות")
    } else if (role === "products") {
      this.productsTable = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "מלאי")
    } else if (role === "orders") {
      this.ordersTable = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "הזמנות")
    } else if (role === "exchanges") {
      this.exchangesComp = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "החלפה והחזרה")
    } else if (role === "cashRegister") {
      this.kupaComp1 = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "קופה")
    } else if (role === "enterToShop") {
      this.beforeKnisaComp = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "לפני כניסה")
    } else if (role === "enterAndPrint") {
      this.knisaComp = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "כניסה והדפסה")
    } else if (role === "settings") {
      this.settingsComp = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "הגדרות שעות חלוקה")
    }else if (role === "phoneLimitStock") {
      this.phoneLimitStock = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "מלאי טלפוני")
    } else if (role === "enterAndPrintAndExchanges") {
      this.knisaComp = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "כניסה והדפסה" || table.name === "החלפה והחזרה")
    } else if (role === "customersAndOrders") {
      this.customersTable = true;
      this.tableListArray = this.tableListArray.filter(table => table.name === "לקוחות" || table.name === "הזמנות");
    } else if (role === "allExceptUsers") {
      this.customersTable = true;
      this.tableListArray = this.tableListArray.filter(table => table.name !== "משתמשים")
    }
    if (!auth) {
      this.$router.replace({ name: "login" })
    }
  },
}
</script>
<style scoped>
.center-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>