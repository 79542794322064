<template>
    <v-dialog v-model="openMode" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.family" label="משפחה"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.father_name" label="אבא"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.mother_name" label="אמא"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.father_id" type="number" hide-spin-buttons
                                    label="ת.ז אבא"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.mother_id" label="ת.ז אמא" type="number"
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone1" label="טלפון 1" type="number"
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone2" label="טלפון 2" type="number"
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone3" label="טלפון 3" type="number"
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.phone4" label="טלפון 4" type="number"
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.email" label="אימייל"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.address" label="רחוב"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.city" label="עיר"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.apartment" label="מספר בית" type="number"
                                    hide-spin-buttons></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.community" label="קהילה"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="status" :item-text="'hebrewName'" :item-value="'name'"
                                    v-model="itemEdited.status" outlined label="סטטוס" :rules="[v => !!v || 'שדה חובה']"
                                    required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.mode" label="מצב משפחתי"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.powerLink_id" label="מזהה פאוורלינק"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.order_details.possible_quantity" label="כמות זכאות להזמנה"
                                    type="number" hide-spin-buttons :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="stations" v-model="itemEdited.order_details.shop_station" :rules="[v => !!v || 'שדה חובה']"
                                    label="נקודת חלוקה">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.order_details.day_to_come" label="יום חלוקה"
                                    type="date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="hours" v-model="itemEdited.order_details.hour_to_come" outlined
                                    label="שעת חלוקה">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle === 'ערוך לקוח'">
                                <v-text-field v-model="itemEdited.order_details.notes" label="הערות"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="formTitle === 'ערוך לקוח'">
                                <v-text-field v-model="itemEdited.order_details.balance" type="number" hide-spin-buttons
                                    label="יתרה"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3F51B5" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#3F51B5" text @click="formTitle === 'ערוך לקוח' ? saveEditedCustomer() : saveNewCustomer()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            family: '',
            father_name: "",
            mother_name: "",
            father_id: "",
            mother_id: "",
            phone1: "",
            phone2: "",
            phone3: "",
            phone4: "",
            email: "",
            address: "",
            city: "",
            apartment: "",
            community: "",
            status: "",
            mode: "",
            powerLink_id: "",
            order_details: {
                possible_quantity: "",
                ordered_quantity: "",
                shop_station: "",
                day_to_come: "",
                hour_to_come: 0,
                balance: 0
            },
        },
        status: [{ hebrewName: "פעיל", name: true }, { hebrewName: "מושבת", name: false }],
        hours: [0, 15, 16, 18, 20],
        stations: ["בית שמש", "ירושלים", "בני ברק", "מודיעין עילית"],
    }),

    methods: {
        async saveNewCustomer() {

            if (this.$refs.form.validate()) {

                const customerJSON = JSON.stringify({ customer: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/customers/create_customer";
                    const res = await fetch(api, ApiServices.requestOptions("POST", customerJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בהוספת לקוח" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id
                        this.$emit("customerAdded", this.itemEdited, "הלקוח נוסף בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved customer: " + error, "red");
                }
            }

        },
        async saveEditedCustomer() {

            if (this.$refs.form.validate()) {

                const customerJSON = JSON.stringify({ customer: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/customers/edit_customer";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", customerJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בעריכת לקוח" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("customerEdited", this.itemEdited, "הלקוח עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit customer: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך לקוח") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        }
    },
}
</script>
<style></style>
  