<template>
    <v-container>
        <div style="margin-top: 70px; margin-bottom: 70px;">
            <v-row class="d-flex justify-center">
                <v-col cols='12' lg='11'>
                    <v-card style="padding: 1%;">
                        <div style="text-align: center; margin-bottom: 40px;">
                            <span class="headline">חפ"ק אונליין</span>
                        </div>
                        <v-form @submit.prevent="submitHandler" ref="form">
                            <v-row style="margin-bottom: 30px; margin-right: 20px;" class="d-flex justify-center">
                                <v-col cols="12" sm="4" md="4">
                                    <v-select :items="dates" :item-text="'date'" :item-value="'date'" hide-details
                                        :rules="[v => !!v || 'שדה חובה']" required v-model="selectedDate" outlined dense
                                        label="בחר תאריך חלוקה">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="4" md="4" v-if="selectedDate !== 'זמן אמת'">
                                    <v-select :items="hours" v-model="selectedHour" outlined dense hide-details
                                        :rules="[v => !!v || 'שדה חובה']" required label="שעת חלוקה">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                    <v-btn color="#3F51B5" dark @click="getSecondReports">
                                        אישור
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                            </v-col>
                        </v-row>

                        <v-row v-if="hasDetails">
                            <v-col class="d-flex justify-center">
                                <div style="display: flex; align-items: end;"> <!-- Added align-items: center; -->
                                    <v-switch v-model="autoRefresh" dense hide-details :label="`רענון אוטומטי`">
                                    </v-switch>
                                    <div style="margin-right: 20px;">
                                        <span v-if="autoRefresh">מתרענן בעוד {{ intervalTimer }} שניות</span>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>

                        <v-row class="my-5" v-if="hasDetails">
                            <v-col cols=12 md="4">
                                <div style="margin-bottom: 10px;" v-if="selectedDate !== 'זמן אמת'">
                                    <span class="headline" style="color: #3f51b5a6;">סה"כ לקוחות לשעה זו: {{
                                        customersThisDateAndHour.length
                                    }}</span>
                                </div>
                                <div style="margin-bottom: 10px;">
                                    <span class="headline" style="color: rgba(0, 128, 0, 0.719);">סה"כ לקוחות כרגע במכירה:
                                        {{
                                            countCustomers.totalCustomersInShop }}</span>
                                </div>
                                <div style="margin-bottom: 10px;">
                                    <span class="headline" style="color: rgba(255, 166, 0, 0.678);">סה"כ לקוחות שיצאו: {{
                                        countCustomers.totalCustomersCameOut
                                    }}</span>
                                </div>
                                <div style="margin-bottom: 10px;" v-if="selectedDate !== 'זמן אמת'">
                                    <span class="headline" style="color: rgba(255, 0, 0, 0.699);">סה"כ לקוחות שלא הגיעו: {{
                                        countCustomers.totalCustomersNotArrived }}</span>
                                </div>

                                <div style="margin-bottom: 10px; margin-top: 50px;" v-if="selectedDate !== 'זמן אמת'">
                                    <span class="headline" style="color: purple;">סה"כ זוגות נעליים לשעה זו:
                                        {{ countOrders.totalOrders }}</span>
                                </div>
                                <div style="margin-bottom: 10px">
                                    <span v-if="selectedDate !== 'זמן אמת'" class="headline" style="color: purple;">סה"כ
                                        זוגות נעליים שנרכשו בשעה זו: {{ countOrders.totalOrdersPurchased }}</span>
                                    <!-- <span v-if="selectedDate === 'זמן אמת'" class="headline" style="color: purple;">סה"כ
                                        זוגות נעליים שנרכשו היום : {{ countOrders.totalOrdersPurchased }}</span> -->
                                </div>
                            </v-col>
                            <v-col cols=12 md="8">
                                <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on" dark @click="downloadExcel()">
                                                mdi-file-download
                                            </v-icon>
                                        </template>
                                        <span>הורד לקובץ אקסל</span>
                                    </v-tooltip>
                                </v-btn>
                                <div class="d-flex justify-center pt-1"
                                    style="background-color: rgba(0, 0, 255, 0.61); border-radius: 15px 15px 0px 0px;">
                                    <span class="text-center "
                                        style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">
                                        רשימת מוזמנים לשעה זו</span>
                                </div>
                                <div class="divider2"></div>
                                <div>
                                    <div>
                                        <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" clearable
                                            hide-details solo flat></v-text-field>
                                    </div>
                                    <div class="divider2"></div>
                                </div>
                                <div style="height: 500px; overflow-y: auto; background-color: white; border-radius: 15px;">
                                    <v-data-table id="table1" :headers="computedHeaders" :items="customersThisDateAndHour"
                                        :search="search" sort-by="index" class="elevation-0" hide-default-footer
                                        :items-per-page="customersThisDateAndHour.length">
                                        <template v-slot:no-data>
                                            <v-row v-if="progressShow" class="my-5">
                                                <v-col class="d-flex justify-center">
                                                    <v-progress-circular indeterminate
                                                        color="#3F51B5"></v-progress-circular>
                                                </v-col>
                                            </v-row>
                                            <div class="ma-5">
                                                <span class="text-center"
                                                    style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                                                    לא נמצאו לקוחות לשעה זו</span>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </div>
                            </v-col>
                        </v-row>


                    </v-card>
                </v-col>
            </v-row>
        </div>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import Utils from '@/util/utils.js'
import TableToExcel from "@linways/table-to-excel";

export default {
    components: {
        SnackBar,
    },
    data() {
        return {
            progressShow: false,
            selectedDate: null,
            selectedHour: null,
            hasDetails: false,
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            baseCustomersHeaders: [
                { text: 'משפחה', value: 'family' },
                { text: 'ת.ז אבא', value: 'father_id' },
                { text: 'טלפון 1', value: 'phone1' },
                { text: 'טלפון 2', value: 'phone2' },
                { text: 'סטטוס הגעה', value: 'order_details.arrival_status' },
            ],
            search: "",
            dates: [],
            hours: [15, 16, 18, 20, "כל השעות"],
            customersThisDateAndHour: [],
            countCustomers: {
                totalCustomersNotArrived: 0,
                totalCustomersInShop: 0,
                totalCustomersCameOut: 0,
            },
            countOrders: {
                totalOrders: 0,
                totalOrdersPurchased: 0,
            },
            intervalId: null,
            intervalTimer: 10,
            countdownId: null,
            autoRefresh: false
        };
    },
    computed: {
    computedHeaders() {
      // Start with the base headers
      const headers = [...this.baseCustomersHeaders];
      // Conditionally add a special header
      if (this.selectedDate === "זמן אמת") {
        headers.splice(headers.length -1, 0, { text: 'נקודת חלוקה', value: 'order_details.shop_station' });
        headers.splice(headers.length -1, 0, { text: 'יום חלוקה', value: 'order_details.day_to_come' });
        headers.splice(headers.length -1, 0, { text: 'שעת חלוקה', value: 'order_details.hour_to_come' });
      }
      return headers;
    },
  },
    methods: {
        async getDates() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/reports/get_dates";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400 || res.status === 404) {
                    this.showSnackBar("תאריך לא נמצא", "red");
                } else if (res.status === 200) {
                    this.dates = jsonObject;
                    this.dates.push("זמן אמת");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error check id: " + error, "red");
            }
        },
        async getSecondReports() {
            if (this.$refs.form.validate()) {

                try {
                    this.progressShow = true;
                    this.hasDetails = false;
                    this.countCustomers = {
                        totalCustomersNotArrived: 0,
                        totalCustomersInShop: 0,
                        totalCustomersCameOut: 0,
                    };
                    this.countOrders = {
                        totalOrders: 0,
                        totalOrdersPurchased: 0,
                    };

                    let hourSelected = this.selectedHour;
                    let dateSelected = this.selectedDate;
                    if (this.selectedHour === "כל השעות") {
                        hourSelected = "all"
                    }
                    if (this.selectedDate === "זמן אמת") {
                        dateSelected = "realTime"
                    }
                    let token = localStorage.getItem("token");
                    let MyJSON = JSON.stringify({ date: dateSelected, hour: hourSelected });
                    let api = process.env.VUE_APP_BASE_URL + "/reports/get_reports_2";
                    const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                    const jsonObject = await res.json();
                    if (res.status === 400 || res.status === 404) {
                        this.showSnackBar("שגיאה בקבלת הנתונים", "red");
                    } else if (res.status === 200) {
                        this.customersThisDateAndHour = jsonObject;
                        this.hasDetails = true;
                        this.calcCustomers();
                        this.formatDetails();
                        this.progressShow = false;
                        console.log(this.customersThisDateAndHour);
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error check id: " + error, "red");
                }
            }
        },
        async reloadData() {
            // Clear any existing interval to prevent multiple intervals from being set
            if (this.intervalId) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
            if (this.countdownId) {
                clearInterval(this.countdownId);
                this.countdownId = null;
            }
            // Immediately load data
            await this.getSecondReports();

            if (this.autoRefresh) {
                // Set a new interval and store its ID
                this.intervalId = setInterval(() => {
                    if (this.countdownId) {
                        clearInterval(this.countdownId);
                        this.countdownId = null;
                    }
                    this.intervalTimer = 10;
                    this.countdownId = setInterval(() => {
                        if (this.intervalTimer > 0) {
                            this.intervalTimer -= 1;
                        }
                    }, 1000);
                    this.intervalTimer = 10;
                    this.getSecondReports();
                }, 10000);

                for (let i = 0; i < 10; i++) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    if (this.intervalTimer > 0) {
                        this.intervalTimer -= 1;
                    }
                }
            }

        },
        downloadExcel() {
            const table = document.getElementById('table1');
            const clonedTable = table.cloneNode(true);

            TableToExcel.convert(clonedTable, {
                name: 'onlineCustomers.xlsx',
                sheet: {
                    name: 'Sheet 1',
                },
            });
        },
        formatDetails() {
            this.customersThisDateAndHour = this.customersThisDateAndHour.map(customer => {
                // Clone the customer object to avoid mutating the original object
                let modifiedCustomer = { ...customer };
                // Modify the arrival_status based on the order.status
                modifiedCustomer.order_details = {
                    ...modifiedCustomer.order_details,
                    arrival_status: modifiedCustomer.order_details.arrival_status === "not arrival" ? "לא הגיע למכירה" :
                        modifiedCustomer.order_details.arrival_status === "arrival" ? "נמצא במכירה" : "יצא מהמכירה",
                        day_to_come: Utils.getFormatDateWithoutHour(modifiedCustomer.order_details.day_to_come)
                };
                return modifiedCustomer;
            });
        },
        calcCustomers() {
            this.customersThisDateAndHour.forEach(customer => {
                if (customer.order_details.arrival_status === "not arrival") {
                    this.countCustomers.totalCustomersNotArrived++;
                } else if (customer.order_details.arrival_status === "arrival") {
                    this.countCustomers.totalCustomersInShop++;
                } else if (customer.order_details.arrival_status === "came out") {
                    this.countCustomers.totalCustomersCameOut++;
                }

                this.countOrders.totalOrders += customer.ordersCount;
                this.countOrders.totalOrdersPurchased += customer.ordersPurchasedCount;

            });
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
        this.getDates();
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
        if (this.countdownId) {
            clearInterval(this.countdownId);
        }
    },
    watch: {
        autoRefresh(newVal, oldVal) {
            this.reloadData();
        }
    }

}
</script>

<style scoped>
.v-dialog .v-card {
    background-color: #f4e1f7;
    /* Light purple background */
}

.v-dialog .v-card-title {
    color: #4a148c;
    /* Dark purple text */
}

.v-dialog .v-btn {
    color: #4a148c;
    /* Dark purple text for buttons */
}

.border-text-field {
    border: 1px solid #eee;
    border-radius: 10px;
}

.divider2 {
    height: 1px;
    background-color: #eee;
}</style>