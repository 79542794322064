<template>
    <v-dialog v-model="openMode" max-width="800px">
        <v-card>
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.size" label="מידה" type="number" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="genders" v-model="itemEdited.gender" hide-details outlined dense
                                    label="בן/בת" :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="types" v-model="itemEdited.type" hide-details outlined dense label="סוג"
                                    :rules="[v => !!v || 'שדה חובה']" required>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="itemEdited.quantity" label="כמות" type="number" hide-spin-buttons
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3F51B5" text @click="openMode = false">
                    ביטול
                </v-btn>
                <v-btn color="#3F51B5" text @click="formTitle === 'ערוך מוצר' ? saveEditedProduct() : saveNewProduct()">
                    שמור
                </v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            _id: "",
            gender: '',
            type: "",
            size: "",
            quantity: "",
        },
        genders: ["בן", "בת"],
        types: ["שבת", "חול"],
    }),

    methods: {
        async saveNewProduct() {

            if (this.$refs.form.validate()) {

                const userJSON = JSON.stringify({ product: this.itemEdited});
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/phoneStock/create_product";
                    const res = await fetch(api, ApiServices.requestOptions("POST", userJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בהוספת משתמש" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.itemEdited._id = jsonObject._id
                        this.$emit("userAdded", this.itemEdited, "המשתמש נוצר בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved user: " + error, "red");
                }
            }

        },
        async saveEditedProduct() {

            if (this.$refs.form.validate()) {

                const userJSON = JSON.stringify({ product: this.itemEdited});
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/phoneStock/edit_product";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", userJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status === 400) {
                        this.showSnackBar("שגיאה בעריכת מוצר" + jsonObject.message, "red");
                    } else if (res.status === 200) {
                        this.$emit("userEdited", this.itemEdited, "המוצר עודכן בהצלחה!", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error edit product: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך מוצר") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
        }
    },
}
</script>
<style></style>
  