<template>
    <v-dialog v-model="openMode" persistent max-width="700px">
        <v-card style="border: 1px solid rgba(209, 5, 5, 0.678);">
            <div>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="#3F51B5" large @click="openMode = false">
                            mdi-window-close
                        </v-icon>
                    </template>
                    <span>חזור לקופה</span>
                </v-tooltip>
            </div>

            <div>
                <div class="d-flex justify-center">
                    <v-card-title class="text-h4" style="color: rgba(209, 5, 5, 0.678);">מצטערים</v-card-title>
                </div>
                <div class="d-flex justify-center" style="margin: 10px;">
                    <span class="text-h4" style="color: rgba(209, 5, 5, 0.678);">{{ content }}</span>
                </div>
                <!-- <div class="d-flex justify-center" style="margin: 10px;">
                    <span class="text-h4">חישוב כמות המידות שהלקוח זכאי בהזמנה
                        זו:</span>
                </div>
                <div class="d-flex justify-center" style="margin: 10px;" v-for="item in alertContentArray">
                    <span class="text-h4" style="">מידה: {{ item.key + " = "  + item.value + " פריטים" }}</span>
                </div> -->
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="#3F51B5" text @click="closeDelete">ביטול</v-btn> -->
                    <v-spacer></v-spacer>
                </v-card-actions>
            </div>
        </v-card>

    </v-dialog>
</template>
  
<script>

export default {
    props: {
        content: String,
        // alertContentObj: Object,
        value: { type: Boolean, default: false },
    },
    data: () => ({

    }),
    methods: {
        closeDelete() {
            this.openMode = false;
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        alertContentArray() {
            return Object.entries(this.alertContentObj).map(([key, value]) => ({
                key,
                value
            }));
        }
    },
    mounted() {
    },
}
</script>
<style></style>
    