import axios from 'axios';

class HebrewDate {
   constructor() {
   }

   static async GetHebrewDate(date) {
      // קבלת תאריך לועזי
      let dd = String(date.getDate()).padStart(2, '0');
      let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
      let yyyy = date.getFullYear();
      // קבלת תאריך עברי
      // קבלת תאריך עברי using axios
      try {
         const response = await axios.get(`https://www.hebcal.com/converter?cfg=json&gy=${yyyy}&gm=${mm}&gd=${dd}&g2h=1`);
         return response.data.hebrew;
      } catch (error) {
        return false;
   }
}




}

export default HebrewDate;