<template>
    <v-dialog v-model="openMode" persistent max-width="500px">
        <v-card style="padding: 20px;">
            <div class="d-flex justify-center ">
                <span> {{ importedSuccess.length }} אנשי קשר נוספו בהצלחה</span>
            </div>
            <div class="d-flex justify-center">
                <span>{{ importedFailed.length }} אנשי קשר נכשלו</span>
            </div>
            <div class="d-flex justify-center">
                <div v-if="progressSave" style="margin-right: 10px;">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn style="color: #0d2c6d;" text @click="refreshPage">בטל ייבוא</v-btn>
                <v-btn style="color: #0d2c6d;" text @click="actionConfirmed">שמור נתונים מאקסל</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import axios from 'axios'

export default {
    props: {
        importedSuccess: Array,
        importedFailed: Array,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressSave: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        sendCall: false,
    }),

    methods: {
        actionConfirmed() {
            this.updateImported();
        },
        refreshPage() {
            this.openMode = false;
        },
        async updateImported() {
            const filteredCodesJSON = JSON.stringify({ customers: this.importedSuccess });
            this.progressSave = true;
            let token = localStorage.getItem("token");

            let api = process.env.VUE_APP_BASE_URL + "/customers/create_customers_imported";

            // Prepare the headers and Axios request configuration
            const config = {
                method: 'post', // HTTP method
                url: api, // API endpoint
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`, // Authorization header
                },
                data: filteredCodesJSON, // Data to be sent in the request body
                timeout: 30000, // Timeout set to 5000 milliseconds (5 seconds)
            };

            try {
                // Making the Axios request with the configured options
                const res = await axios(config);
                this.progressSave = false; // Assuming this is meant to control a loading indicator
                // Handling the response based on the status code
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בשמירת הנתונים", "red");
                } else if (res.status === 200) {
                    this.openMode = false; // Assuming this is meant to control modal/dialog visibility
                    this.$emit("importedSuccessAdded");
                } else {
                    this.showSnackBar("שגיאה בשמירת הנתונים", "red");
                }
            } catch (error) {
                this.progressSave = false; // Handling the error case by stopping the loading indicator
                if (error.response && error.response.status === 400) {
                    this.showSnackBar("Error saved subscribes: " + error, "red");
                } else if (error.code === 'ECONNABORTED') {
                    this.showSnackBar("Error Request timeout", "red");
                } else {
                    this.showSnackBar("Error saved list: " + error.message, "red");
                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
  
    