<template>
  <v-container>
    <div style="margin-top: 20px; margin-bottom: 70px;">
      <v-row class="d-flex justify-center">
        <v-col cols='12' lg='11'>
          <v-card
            style="padding: 1%; background: linear-gradient(180deg, rgba(219,221,233,1) 1%, rgb(196, 207, 255) 70%); box-shadow: 0 0 10px #fff;">
            <div style="text-align: center; margin-bottom: 40px;">
              <span class="headline">החלפה והחזרה</span>
            </div>
            <v-form @submit.prevent="submitHandler" ref="form">
              <v-row style="margin-bottom: 30px; margin-right: 20px;">
                <v-col cols="3" background-color="white" class="border-text-field"
                  style="background-color: white; padding: 0px 10px 10px 10px;">
                  <v-text-field v-model="card_id" autofocus label="חיפוש לפי תעודת זהות" @keydown.enter="handleEnterKey()"
                    clearable @input="clearData" :rules="[v => !!v || 'שדה חובה']" required type="number" hide-details
                    hide-spin-buttons flat></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex justify-center" v-if="customer.family">
              <div style="width: 70%; text-align: center; background-color: rgba(114, 27, 63, 0.753);">
                <div>
                  <span style="font-size: x-large; color: white;">משפחת {{ customer.family }}</span>
                </div>
                <div>
                  <span style="font-size: x-large; color: white;">{{ customer.address + " " + customer.apartment }}</span>
                </div>
              </div>
            </div>
            <v-row class="d-flex justify-center" style="margin-top: 30px;" v-if="customer.father_id">
              <v-col cols="12" sm="5">
                <v-btn small color="rgba(0, 0, 255, 0.61)" dark @click="openAddOrderDialog()">
                  הוסף הזמנה
                </v-btn>
              </v-col>
              <v-col cols="12" sm="5">
                <span style="font-size: x-large; font-weight: 600;">יתרה בחשבון: </span>
                <span style="font-size: x-large; font-weight: 600; color: green;">{{
                  customer.order_details.balance }}
                </span>
                <span style="font-size: x-large; font-weight: 600;">₪</span>
              </v-col>
            </v-row>
            <v-row style="margin-top: 1px;" class="d-flex justify-center">
              <v-col cols="12" md="5">
                <div style="background-color: white; border-radius: 15px;">
                  <div>
                    <div class="d-flex justify-center pt-1"
                      style="background-color: rgba(0, 0, 255, 0.61); border-radius: 15px 15px 0px 0px;">
                      <span class="text-center "
                        style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">נעליים
                        שהוזמנו</span>
                    </div>
                    <div class="divider2"></div>
                  </div>
                  <div style="height: 350px; overflow-y: auto; background-color: white; border-radius: 15px;">
                    <v-data-table id="table1" :headers="orderedHeaders" :items="orderedProducts" sort-by="index"
                      class="elevation-0" hide-default-footer :items-per-page="orderedProducts.length">

                      <template v-slot:item.actions="{ item, index }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="#3F51B5" medium class="me-4"
                              @click="openEditOrderDialog(item, index)">
                              mdi-pencil
                            </v-icon>
                          </template>
                          <span>עריכה</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="#3F51B5" medium class="me-4"
                              @click="openDeleteOrderDialog(item, index)">
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>מחיקה</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="#3F51B5" medium @click="printItem(item)">
                              mdi-printer
                            </v-icon>
                          </template>
                          <span>הדפס הזמנה</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:item.index="{ item, index }">
                        <tr>
                          <td>{{ index + 1 }}</td>
                        </tr>
                      </template>

                      <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                          <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                          </v-col>
                        </v-row>
                        <div class="ma-5">
                          <span class="text-center"
                            style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                            לא נמצאו פריטים</span>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-col>


              <v-col cols="12" md="5">
                <div style="background-color: white; border-radius: 15px;">
                  <div>
                    <div class="d-flex justify-center pt-1"
                      style="background-color: rgba(114, 27, 63, 0.836); border-radius: 15px 15px 0px 0px;">
                      <span class="text-center "
                        style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">נעליים
                        שנרכשו
                      </span>
                    </div>
                    <div class="divider2"></div>
                  </div>
                  <div>
                    <div >
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" clearable
                         type="number" hide-details hide-spin-buttons solo flat></v-text-field>
                    </div>
                    <div class="divider2"></div>
                  </div>
                  <div style="height: 300px; overflow-y: auto; background-color: white; border-radius: 15px;">
                    <v-data-table id="table1" :headers="purchasedHeaders" :items="purchasedProducts" sort-by="index" :search="search"
                      class="elevation-0" hide-default-footer :items-per-page="purchasedProducts.length">

                      <template v-slot:item.actions="{ item, index }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="#3F51B5" medium class="me-4" @click="openReturnDialog(item, index)">
                              mdi-keyboard-return
                            </v-icon>
                          </template>
                          <span>החזרת נעל</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="#3F51B5" medium @click="printCopyInvoice(item)">
                              mdi-printer
                            </v-icon>
                          </template>
                          <span>הדפס קבלה</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:item.index="{ item, index }">
                        <tr>
                          <td>{{ index + 1 }}</td>
                        </tr>
                      </template>

                      <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                          <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                          </v-col>
                        </v-row>
                        <div class="ma-5">
                          <span class="text-center"
                            style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                            לא נמצאו פריטים</span>
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center" style="margin-top: 50px;">
              <v-col cols="12" md="6">
                <div style="background-color: white; border-radius: 15px;">
                  <div>
                    <div class="d-flex justify-center pt-1"
                      style="background-color: rgba(255, 0, 0, 0.658); border-radius: 15px 15px 0px 0px;">
                      <span class="text-center "
                        style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">אירועים
                        ללקוח
                      </span>
                    </div>
                    <div class="divider2"></div>
                  </div>
                  <div style="height: 300px; overflow-y: auto; background-color: white; border-radius: 15px;">
                    <v-data-table id="table1" :headers="eventsHeaders" :items="events" sort-by="createdAt" sort-desc
                      class="elevation-0" hide-default-footer :items-per-page="events.length">

                      <template v-slot:item.index="{ item, index }">
                        <tr>
                          <td>{{ index + 1 }}</td>
                        </tr>
                      </template>

                      <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                          <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                          </v-col>
                        </v-row>
                        <div class="ma-5">
                          <span class="text-center"
                            style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                            לא נמצאו דיווחים</span>
                        </div>
                      </template>
                    </v-data-table>
                  </div>

                </div>
              </v-col>
            </v-row>
            <!-- <v-row v-if="progressShow" class="my-5">
                          <v-col class="d-flex justify-center">
                              <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                          </v-col>
                      </v-row> -->
            <v-card-actions>
              <span style="color: rgba(0, 128, 0, 0.637); font-size: x-large;">שם הנציג: {{ name }}</span>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AddEditOrderDialog v-model="editOrderDialog" v-if="editOrderDialog" :itemToEdit="editedOrderItem"
      :customerZeut="customer.father_id" :formTitle="formTitle" @orderEdited="editedOrderSaved"
      @orderAdded="newOrderSaved" />

    <AlerOrdertDialog v-model="alertOrderDialog" v-if="alertOrderDialog" :item="itemOrderToDelete"
      @orderDeleted="orderDeleted" />

    <AlertReturnDialog v-model="alertReturnDialog" v-if="alertReturnDialog" :item="itemOrderToReturn"
      @orderReturned="orderReturned" />

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-container>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import AddEditOrderDialog from '@/components/orders/dialogs/AddEditDialog'
import AlerOrdertDialog from '@/components/orders/dialogs/AlertDialog'
import AlertReturnDialog from '@/components/exchanges/dialogs/AlertReturnDialog'
import PrintTemplate from '@/components/knisa/PrintTemplate'
import CopyInvoiceTemplate from '@/components/exchanges/CopyInvoiceTemplate'
import Auth from '@/services/auth.service.js'
import Utils from '@/util/utils.js'
import Vue from 'vue';

export default {
  components: {
    SnackBar,
    AddEditOrderDialog,
    AlerOrdertDialog,
    AlertReturnDialog,
    PrintTemplate,
    CopyInvoiceTemplate
  },
  data() {
    return {
      progressShow: false,
      editOrderDialog: false,
      alertOrderDialog: false,
      alertReturnDialog: false,
      editedOrderItem: {},
      itemOrderToDelete: {},
      itemOrderToReturn: {},
      card_id: '',
      isPrinting: false,
      itemsToPrint: [],
      snackbar: false,
      snackbarColorBt: "green",
      snacbarText: "",
      choosePrinter: false,
      name: "",
      search: "",
      customer: {},
      orderedProducts: [],
      purchasedProducts: [],
      events: [],
      orderedHeaders: [
        { text: '#', value: 'index', sortable: false },
        { text: 'מידה', value: 'size' },
        { text: 'בן/בת', value: 'gender' },
        { text: 'סוג', value: 'type' },
        { text: 'פעולות', value: 'actions', sortable: false },
      ],
      purchasedHeaders: [
        { text: '#', value: 'index', sortable: false },
        { text: 'ברקוד', value: 'barcode' },
        { text: 'מידה', value: 'size' },
        { text: 'בן/בת', value: 'gender' },
        { text: 'סוג', value: 'type' },
        { text: 'מחיר', value: 'price' },
        { text: 'פעולות', value: 'actions', sortable: false },
      ],
      eventsHeaders: [
        { text: '#', value: 'index', sortable: false },
        { text: 'תאריך', value: 'createdAt' },
        { text: 'פעולה', value: 'event' },
        { text: "פרטים", value: 'details' },
      ],
    };
  },
  methods: {
    async getDetailsForExchange() {
      if (this.$refs.form.validate()) {
        try {

          this.progressShow = true;
          let token = localStorage.getItem("token");
          let MyJSON = JSON.stringify({ card_id: this.card_id });

          let api = process.env.VUE_APP_BASE_URL + "/exchanges/get_customer_details_and_orders";
          const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
          const jsonObject = await res.json();
          this.progressShow = false;
          if (res.status === 400 || res.status === 404) {
            this.showSnackBar("לקוח לא נמצא", "red");
          } else if (res.status === 200) {
            this.customer = jsonObject.customer;
            this.orderedProducts = jsonObject.orders.filter(order => order.status !== "paid");
            this.purchasedProducts = jsonObject.orders.filter(order => order.status === "paid");
            this.events = jsonObject.events;
            console.log(this.events.length);

            this.formatStatus();
          }
        } catch (error) {
          this.progressShow = false;
          this.showSnackBar("Error check id: " + error, "red");
        }
      }
    },
    formatStatus() {
      this.orderedProducts = this.orderedProducts.map((order) => {
        return {
          ...order,
          status: order.status === "order_by_phone" ? "הוזמן טלפונית" : order.status === "order_manually" ? "הוזמן ידנית" : "נרכש",
        }
      });
      this.events = this.events.map((event) => {
        return {
          ...event,
          createdAt: Utils.getFormatDate2(event.createdAt),
        }
      });
    },
    clearData(value) {
      if (!value) {
        this.editedOrderItem = {};
        this.itemOrderToDelete = {};
        this.itemOrderToReturn = {};
        this.itemsToPrint = [];
        this.customer = {};
        this.orderedProducts = [];
        this.purchasedProducts = [];
        this.events = [];
      }
    },
    openEditOrderDialog(item) {
      this.editedOrderItem = item;
      this.editOrderDialog = true
      this.formTitle = "ערוך הזמנה"
    },
    openAddOrderDialog() {
      this.editOrderDialog = true
      this.formTitle = "הוסף הזמנה"
    },
    openDeleteOrderDialog(item) {
      this.itemOrderToDelete = item;
      this.alertOrderDialog = true
    },
    openReturnDialog(item) {
      this.itemOrderToReturn = item;
      this.alertReturnDialog = true
    },
    newOrderSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.orderedProducts.push(item);
    },
    editedOrderSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      const index = this.orderedProducts.findIndex(order => order._id === item._id);
      Object.assign(this.orderedProducts[index], item);
    },
    orderDeleted(item, text, color) {
      if (!item) {
        this.showSnackBar(text, color)
        return
      }
      const index = this.orderedProducts.findIndex(order => order._id === item._id);
      this.orderedProducts.splice(index, 1);
      this.showSnackBar(text, color)
    },
    orderReturned(item, price, text, color) {
      if (item === "") {
        this.showSnackBar(text, color)
        return
      }
      const index = this.purchasedProducts.findIndex(order => order._id === item._id);
      this.purchasedProducts.splice(index, 1);
      this.orderedProducts.push(item);
      this.customer.order_details.balance = Number(this.customer.order_details.balance) + Number(price);
      this.showSnackBar(text, color)
    },
    printItem(item) {

      item.family = this.customer.family;
      item.address = this.customer.address;
      item.apartment = this.customer.apartment;
      item.city = this.customer.city;
      item.father_id = this.customer.father_id;
      item.shop_station = this.customer.order_details.shop_station;
      item.day_to_come = this.customer.order_details.day_to_come;
      item.hour_to_come = this.customer.order_details.hour_to_come;

      const printContent = document.createElement('div');

      let ComponentClass = Vue.extend(PrintTemplate);
      let instance = new ComponentClass({
        propsData: { item, index: null, allItemsCount: null}
      });
      instance.$mount(); // Mount the component instance to generate HTML
      // Append the instance to a temporary container
      printContent.appendChild(instance.$el);


      // Open and configure the print window
      const printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
      printWindow.document.write('<html dir="rtl"><head><title></title></head><body>');
      printWindow.document.write('<style>.print-page { page-break-after: always; }</style>'); // Include the style directly
      printWindow.document.body.appendChild(printContent); // Append the container with all items
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      this.$nextTick(() => {
        printWindow.print(); // Invoke the print dialog
      });
    },

    
    printCopyInvoice(item) {
      const printContent = document.createElement('div');
      let ComponentClass = Vue.extend(CopyInvoiceTemplate);
      let instance = new ComponentClass({
        propsData: { customer: this.customer, productInCart: [item]}
      });
      instance.$mount(); // Mount the component instance to generate HTML
      // instance.$el.classList.add('print-page'); // Ensure the page break class is applied

      // Append the instance to a temporary container
      printContent.appendChild(instance.$el);

      // Open and configure the print window
      const printWindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
      printWindow.document.write('<html dir="rtl"><head><title></title></head><body>');
      printWindow.document.write('<style>.print-page { page-break-after: always; }</style>'); // Include the style directly
      printWindow.document.body.appendChild(printContent); // Append the container with all items
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      this.$nextTick(() => {
        printWindow.print(); // Invoke the print dialog
        //    printWindow.close(); // Consider removing this line or managing window closing differently.
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    handleEnterKey() {
      this.getDetailsForExchange(); // Trigger the button click event
    },


  },
  mounted() {
    this.name = Auth.getUserName();
  },
};
</script>

<style scoped>
.v-dialog .v-card {
  background-color: #f4e1f7;
  /* Light purple background */
}

.v-dialog .v-card-title {
  color: #4a148c;
  /* Dark purple text */
}

.v-dialog .v-btn {
  color: #4a148c;
  /* Dark purple text for buttons */
}

.border-text-field {
  border: 1px solid #eee;
  border-radius: 10px;
}

.divider2{
  height: 1px;
  background-color: #eee;
}
</style>
