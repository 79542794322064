<template>
    <v-container>
        <div style="margin-top: 70px; margin-bottom: 70px;">
            <v-row class="d-flex justify-center">
                <v-col cols='12' xl='8'>
                    <v-card
                        style="padding: 1%; background: linear-gradient(180deg, rgba(219,221,233,1) 1%, rgba(225,196,255,1) 70%); box-shadow: 0 0 10px #fff;">
                        <div style="text-align: center; margin-bottom: 70px;">
                            <span class="headline">קופה רושמת</span>
                        </div>
                        <div style="text-align: center; margin-bottom: 70px;">
                            <img src="@/assets/icon_membership.png" style="max-width: 100%;">
                        </div>
                        <v-form @submit.prevent="submitHandler" ref="form">
                            <v-row class=" d-flex justify-center" style="margin-bottom: 100px;">
                                <v-col cols="3" background-color="white" class="border-text-field"
                                    style="background-color: white; padding: 0px 10px 10px 10px;">
                                    <v-text-field v-model="card_id" autofocus label="הזן מספר תעודת זהות" @keydown.enter="handleEnterKey()"
                                        :rules="[v => !!v || 'שדה חובה']" required type="number" hide-details
                                        hide-spin-buttons flat></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                           <span style="color: rgba(0, 128, 0, 0.637); font-size: x-large;">שם קופאי: {{ name }}</span>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
    components: {
        SnackBar
    },
    data() {
        return {
            progressShow: false,
            content: '',
            card_id: '',
            isPrinting: false,
            itemsToPrint: [],
            snackbar: false,
            snackbarColorBt: "green",
            snacbarText: "",
            choosePrinter: false,
            name: "",
        };
    },
    methods: {
        async checkId() {
            if (this.$refs.form.validate()) {
                try {

                    this.progressShow = true;
                    let token = localStorage.getItem("token");
                    let MyJSON = JSON.stringify({ card_id: this.card_id });

                    let api = process.env.VUE_APP_BASE_URL + "/kupa/check_card_id";
                    const res = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                    const jsonObject = await res.json();
                    this.progressShow = false;
                    if (res.status === 400 || res.status === 404) {
                        this.showSnackBar("שגיאה בבדיקת הנתונים", "red");
                    } else if (res.status === 200) {
                        if (!jsonObject.result) {
                            this.showSnackBar("הלקוח אינו קיים במאגר", "red");
                        } else {
                            this.card_id = "";
                            this.$emit("moveToKupa2", jsonObject.customer, jsonObject.orders);
                        }
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error check id: " + error, "red");
                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        handleEnterKey() {
                this.checkId(); // Trigger the button click event
        },
       
       
    },
    mounted() {
        this.name = Auth.getUserName();
    },
};
</script>
  
<style scoped>
.v-dialog .v-card {
    background-color: #f4e1f7;
    /* Light purple background */
}

.v-dialog .v-card-title {
    color: #4a148c;
    /* Dark purple text */
}

.v-dialog .v-btn {
    color: #4a148c;
    /* Dark purple text for buttons */
}

.border-text-field {
    border: 1px solid #eee;
    border-radius: 10px;
}
</style>
  