<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card>
            <div class="d-flex justify-center">
                <v-card-title class="text-h5">פריט משנה קודמת</v-card-title>
            </div>
            <div>
                <v-card-title class="text-h7">נא לבחור את פרטי הנעל:</v-card-title>
            </div>
            <div style="margin-right: 20px; margin-left: 20px; margin-bottom: 50px;">
                <v-form @submit.prevent="submitHandler" ref="form">
                    <v-row>
                        <v-col cols="12" sm="6" style="padding-bottom: 0px; padding-top: 0px;">
                            <v-radio-group v-model="genderRadioGroup" :rules="[v => !!v || 'שדה חובה']">
                                <v-radio v-for="n in groupGender" color="#0d2c6d" :key="n" :label="`${n}`"
                                    :value="n"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="6" style="padding-bottom: 0px; padding-top: 0px;">
                            <v-radio-group v-model="typeRadioGroup" :rules="[v => !!v || 'שדה חובה']">
                                <v-radio v-for="n in groupType" color="#0d2c6d" :key="n" :label="`${n}`"
                                    :value="n"></v-radio>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                </v-form>
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#3F51B5" text @click="setBarcode()">אישור</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

    </v-dialog>
</template>

<script>

export default {
    props: {
        barcode: String,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        formatedBarcode: "",
        genderRadioGroup: "",
        typeRadioGroup: "",
        groupGender: ["בן", "בת"],
        groupType: ["שבת", "חול"],

    }),
    methods: {
        setBarcode() {
            if (this.$refs.form.validate()) {

                if (this.genderRadioGroup === "בן" && this.typeRadioGroup === "שבת") {
                    this.formatedBarcode = '910023' + this.formatedBarcode.substring(6);
                } else if (this.genderRadioGroup === "בן" && this.typeRadioGroup === "חול") {
                    this.formatedBarcode = '920023' + this.formatedBarcode.substring(6);
                } else if (this.genderRadioGroup === "בת" && this.typeRadioGroup === "שבת") {
                    this.formatedBarcode = '930023' + this.formatedBarcode.substring(6);
                } else if (this.genderRadioGroup === "בת" && this.typeRadioGroup === "חול") {
                    this.formatedBarcode = '940023' + this.formatedBarcode.substring(6);
                }

                this.$emit("setBarcode", this.formatedBarcode);
                this.openMode = false;
            }
        },

    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.formatedBarcode = JSON.parse(JSON.stringify(this.barcode));
    },
}
</script>
<style></style>