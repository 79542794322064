<template >
  <div style="margin-top: 70px; margin-bottom: 70px;">
    <v-row class="d-flex justify-center">
      <v-col cols='12' xl='11'>
        <v-data-table id="table1" :headers="headers" :items="stock" :search="search" sort-by="size"
          class="elevation-4" :items-per-page="itemsPerPage"
          :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, -1], 'items-per-page-text': 'שורות בעמוד:' }">

          <template v-slot:top>
            <div class="d-flex justify-center pt-3">
              <span class="text-center flex-grow-1"
                style="font-weight: bold; font-size: 30px; font-family: Times, serif; color: #5C6BC0;">מלאי טלפוני</span>
            </div>
            <div class="divider"></div>

            <v-card elevation="0">
              <v-card-title>
                <div class="d-flex flex-wrap pa-5 ">
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="openCreateDIalog()">
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>הוסף חדש</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn small color="#3F51B5" dark class="mb-2 ms-2" style="max-width: 20px;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" dark @click="downloadExcel()">
                          mdi-file-download
                        </v-icon>
                      </template>
                      <span>הורד לקובץ אקסל</span>
                    </v-tooltip>
                  </v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" single-linehide-details>
                </v-text-field>
              </v-card-title>
            </v-card>
          </template>

          <template v-slot:item.index="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
            </tr>
          </template>

          <template v-slot:no-data>
            <v-row v-if="progressShow" class="my-5">
              <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
              </v-col>
            </v-row>
            <div class="ma-5">
              <span class="text-center" style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו
                נתונים</span>
            </div>
            <v-btn color="#3F51B5" dark style="margin-bottom: 10px;" @click="getPhoneStock()">
              רענן
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#3F51B5" medium class="mr-2 me-3" @click="openEditDialog(item, index)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>עריכה</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#3F51B5" medium class="me-3" @click="openDeleteDialog(item, index)">
                  mdi-delete
                </v-icon>
              </template>
              <span>מחיקה</span>
            </v-tooltip>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
      @userAdded="newUserSaved" @userEdited="editedUserSaved"> </AddEditDialog>

    <AlertDialog v-model="alertDialog" v-if="alertDialog" :item="itemToDelete" @userDeleted="userDeleted"> </AlertDialog>

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </div>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import TableToExcel from "@linways/table-to-excel";
import AddEditDialog from '@/components/phoneStock/dialogs/AddEditDialog'
import AlertDialog from '@/components/phoneStock/dialogs/AlertDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import Utils from "@/util/utils";

export default {
  components: {
    AddEditDialog,
    AlertDialog,
    SnackBar
  },
  data: () => ({
    progressSave: false,
    headers: [
      { text: '#', value: "index", sortable: false },
      { text: 'מידה', value: 'size' },
      { text: 'בן/בת', value: 'gender' },
      { text: 'סוג', value: 'type' },
      { text: 'כמות במלאי', value: 'quantity' },
      { text: 'כמות שהוזמנה', value: 'quantity_ordered'},
      { text: 'עודכן בתאריך', value: 'updatedAt' },
      { text: 'פעולות', value: 'actions', sortable: false },

    ],
    alertDialog: false,
    addEditDialog: false,
    formTitle: "",
    stock: [],
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    editedItem: {},
    itemToDelete: {},
    search: "",
    itemsPerPage: 20, // default items per page
  }),
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  methods: {

    async getPhoneStock() {
      try {
        this.stock = [];
        this.progressShow = true;
        let token = localStorage.getItem("token");

        let api = process.env.VUE_APP_BASE_URL + "/phoneStock/get_products";
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = false;
        if (res.status === 400) {
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.stock = jsonObject;
          console.log(this.stock);
          this.formatDetails();
        }
      } catch (error) {
        this.progressShow = false;
        this.showSnackBar("Error get subscribes list: " + error, "red");
      }
    },
    formatDetails() {
      this.stock = this.stock.map((product) => {
        return {
          ...product,
          updatedAt: Utils.getFormatDate2(product.updatedAt),
        }
      });
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    downloadExcel() {
      const originalHeaders = JSON.parse(JSON.stringify(this.headers));
      this.headers = originalHeaders.filter(header => header.value !== "index" && header.value !== "actions");

      this.setItemsPerPage(-1); // Set to display all items
      // Wait for the next tick to ensure the table is updated
      this.$nextTick(() => {
        // Now export the table as you normally would
        const table = document.getElementById('table1');
        const clonedTable = table.cloneNode(true);
        this.headers = 

        TableToExcel.convert(clonedTable, {
          name: 'phoneStock.xlsx',
          sheet: {
            name: 'Sheet 1',
          },
        });

        // Optionally, reset the itemsPerPage after export
        this.setItemsPerPage(20); // Or any default value
        this.headers = originalHeaders;
      });
    },
    // Method to set items per page
    setItemsPerPage(value) {
      this.itemsPerPage = value;
    },
    openEditDialog(item, index) {
      const originalItem = this.stock.find(product => product._id === item._id);
      this.editedItem = originalItem;
      this.formTitle = "ערוך מוצר"
      this.addEditDialog = true
    },

    openDeleteDialog(item, index) {
      this.itemToDelete = item
      this.formTitle = "האם אתה בטוח שברצונך למחוק את המוצר?"
      this.alertDialog = true
    },
    openCreateDIalog() {
      this.formTitle = "הוסף מוצר"
      this.editedItem = {}
      this.addEditDialog = true
    },
    newUserSaved(item, text, color) {

      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      this.stock.push(item);
    },
    editedUserSaved(item, text, color) {
      this.showSnackBar(text, color)
      if (item === "") {
        return
      }
      const index = this.stock.findIndex(product => product._id === item._id);
      Object.assign(this.stock[index], item);
    },
    userDeleted(item, text, color) {
      if (!item) {
        this.showSnackBar(text, color)
        return
      }
      const index = this.stock.findIndex(product => product._id === item._id);
      this.stock.splice(index, 1);
      this.showSnackBar(text, color)
    },
  },
  mounted() {
    this.getPhoneStock();
  },
}
</script>
<style>
.divider {
  border-top: 0.5px solid #cfcccc;
  /* Set the border style, width, and color */
  margin-top: 10px;
  /* Optional: Add margin to adjust spacing */
  margin-bottom: 10px;
  /* Optional: Add margin to adjust spacing */
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-info span {
  margin-right: 2px;
}

.logout-button-container {
  display: flex;
  align-items: center;
}
</style>
  