<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card>
            <div :style="{border: content.code ? '1px solid rgba(209, 5, 5, 0.678)' : '1px solid rgba(0, 128, 0, 0.705)'}">
            <div class="d-flex justify-center" v-if="!content.result">
                <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">מצטערים</v-card-title>
            </div>
            <div class="d-flex justify-center" v-if="content.code === '1'">
                <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">אינך רשום במערכת</v-card-title>
            </div>
            <div class="d-flex justify-center" v-else-if="content.code === '2'">
                <div>
                    <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">התור שלך נקבע לתאריך:</v-card-title>
                    <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">{{ hebrewDate }} - {{ date
                    }}</v-card-title>
                    <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">משעה: {{ hour_start
                    }} עד שעה {{ hour_end }}</v-card-title>
                </div>
            </div>
            <div class="d-flex justify-center" v-else-if="content.code === '3'">
                <div>
                    <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">התור שלך נקבע משעה: {{ hour_start
                    }} עד שעה {{ hour_end }}</v-card-title>
                </div>
            </div>
            <div class="d-flex justify-center" v-else-if="content.code === '5'">
                <div>
                    <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">לא קבעת תור</v-card-title>
                </div>
            </div>
            <div class="d-flex justify-center" v-else-if="content.code === '6'">
                <div>
                    <v-card-title class="text-h5" style="color: rgba(209, 5, 5, 0.678);">הלקוח אינו פעיל</v-card-title>
                </div>
            </div>
            <div class="d-flex justify-center" v-else>
                <div>
                    <div>
                        <v-card-title class="text-h4" style="color: rgba(0, 128, 0, 0.705);">הנך מורשה להיכנס
                            למכירה!</v-card-title>
                    </div>
                    <div>
                        <v-card-title class="text-h5" style="color: rgba(0, 128, 0, 0.705);">התור שלך נקבע משעה: {{
                            hour_start
                        }} עד שעה {{ hour_end }}</v-card-title>
                    </div>
                </div>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn color="#3F51B5" text @click="closeDelete">ביטול</v-btn> -->
                <v-spacer></v-spacer>
            </v-card-actions>
            </div>
        </v-card>

    </v-dialog>
</template>
  
<script>
import HebrewDate from '@/util/HebrewDate'

export default {
    props: {
        content: Object,
        value: { type: Boolean, default: false },
    },
    data: () => ({
        date: "",
        hebrewDate: "",
        hour_start: "",
        hour_end: "",

    }),
    methods: {
        closeDelete() {
            this.openMode = false;
        },
        async setHebrewDate(date) {
            this.hebrewDate = await HebrewDate.GetHebrewDate(date);
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        const appointmentDate = new Date(this.content.appointmentDate);
        this.date = `${String(appointmentDate.getDate()).padStart(2, "0")}/${String(appointmentDate.getMonth() + 1).padStart(2, "0")}/${appointmentDate.getFullYear()}`;
        this.setHebrewDate(appointmentDate);
        let hourStart = String(this.content.appointmentHour);
        let hourEnd = String(this.content.appointmentHour + 2);
        if (hourStart.length == 1) {
            this.hour_start = "0" + hourStart + ":00"
        } else {
            this.hour_start = hourStart + ":00"
        }
        if (hourEnd.length == 1) {
            this.hour_end = "0" + hourEnd + ":00"
        } else {
            this.hour_end = hourEnd + ":00"
        }
    },
}
</script>
<style></style>
    