<template>
    <div style="margin-top: 50px;">
        <v-row style="margin-bottom: 20px;">
            <v-col cols="12" class="d-flex justify-center">
                <v-card style="padding: 10px; border: 1px solid #3f51b53f;">
                    <div>
                        <span style="font-weight: 600; font-size: x-large; margin-left: 20px;">דוח הזמנות לפי דגם</span>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
            <v-col cols="auto" sm="10">
                <v-card style="padding: 20px; margin: 20px; border: 1px solid #3f51b53f;">
                    <div class="pt-1 d-flex justify-content-between"
                        style="background-color: rgba(0, 0, 255, 0.61); border-radius: 15px 15px 0px 0px;">
                        <v-btn small icon class="mb-2 ms-2" style="max-width: 20px; ">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" style="background-color: white;" @click="downloadExcel()">
                                        mdi-file-download
                                    </v-icon>
                                </template>
                                <span>הורד לקובץ אקסל</span>
                            </v-tooltip>
                        </v-btn>
                        <div class="text-center flex-grow-1">
                            <span style="font-weight: bold; font-size: 20px; font-family: Times, serif; color: white;">
                                הזמנות לפי דגם
                            </span>
                        </div>
                    </div>
                    <div class="divider2"></div>
                    <div style="height: 550px; overflow-y: auto; background-color: white; border-radius: 15px;">
                        <div>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" clearable type="number"
                                hide-details hide-spin-buttons solo flat></v-text-field>
                        </div>
                        <div class="divider2"></div>
                        <v-data-table id="table1" :headers="dataHeaders" :items="dataFormated" sort-by="index"
                            class="elevation-0" :search="search" hide-default-footer :items-per-page="data.length">
                            <template v-slot:no-data>
                                <v-row v-if="progressShow" class="my-5">
                                    <v-col class="d-flex justify-center">
                                        <v-progress-circular indeterminate color="#3F51B5"></v-progress-circular>
                                    </v-col>
                                </v-row>
                                <div class="ma-5">
                                    <span class="text-center"
                                        style="font-weight: bold; font-size: 25px; font-family: Times, serif;">
                                        לא נמצאו הזמנות</span>
                                </div>
                            </template>

                            <template v-slot:item.index="{ index }">
                                <tr>
                                    <td>{{ index + 1 }}</td>
                                </tr>
                            </template>

                        </v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
<script>
import SnackBar from '@/components/widgets/snackBar.vue'
import ApiServices from '@/services/api.service'
import * as XLSX from 'xlsx/xlsx.mjs';

export default {
    name: 'agent',
    components: {
        SnackBar
    },
    data: () => ({
        dataHeaders: [
            { text: '#', value: 'index', sortable: false },
            { text: 'דגם', value: 'barcode' },
            { text: 'כמות הזמנות', value: 'ordersCount' },
            { text: 'ירושלים', value: 'ירושלים' },
            { text: 'בית שמש', value: 'בית שמש' },
            { text: 'בני ברק', value: 'בני ברק' },
            { text: 'מודיעין עילית', value: 'מודיעין עילית' },
        ],
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        data: [],
        search: ""
    }),
    computed: {
        dataFormated() {
            return this.data.map(item => ({
                barcode: item.barcode,
                ordersCount: item.ordersCount,
                "ירושלים": item["ירושלים"] ? item["ירושלים"].length : 0,
                "בית שמש": item["בית שמש"] ? item["בית שמש"].length : 0,
                "בני ברק": item["בני ברק"] ? item["בני ברק"].length : 0,
                "מודיעין עילית": item["מודיעין עילית"] ? item["מודיעין עילית"].length : 0,
            }));
        }
    },
    methods: {
        async getFourthReports() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/reports/get_reports_4";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    console.log(jsonObject);
                    this.data = jsonObject;

                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get first report: " + error, "red");
            }
        },
        async refreshDetail() {
            this.getFourthReports();
        },
        downloadExcel() {

            const originalHeaders = JSON.parse(JSON.stringify(this.dataHeaders));

            this.dataHeaders = this.dataHeaders.filter(header => {
                return header.value !== "index"; // Add 'return' to ensure the correct boolean value is returned
            });

            this.$nextTick(() => {
                // Clone the table
                const table = document.getElementById('table1');
                const clonedTable = table.cloneNode(true);

                TableToExcel.convert(clonedTable, {
                    name: 'barcodes.xlsx',
                    sheet: {
                        name: 'Sheet 1',
                    },
                });

                 this.dataHeaders = originalHeaders;
            });
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
        this.getFourthReports();
    },
}
</script>
<style scoped></style>