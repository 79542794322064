<template>
    <v-dialog v-model="openMode" persistent max-width="500px">
        <v-card style="padding: 20px;">
            <div class="d-flex justify-center ">
                <span> {{ importedSuccess.length }} אנשי קשר נוספו בהצלחה</span>
            </div>
            <div class="d-flex justify-center">
                <span>{{ importedFailed.length }} אנשי קשר נכשלו</span>
            </div>
            <div class="d-flex justify-center">
                <div v-if="progressSave" style="margin-right: 10px;">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn style="color: #0d2c6d;" text @click="refreshPage">בטל ייבוא</v-btn>
                <v-btn style="color: #0d2c6d;" text @click="actionConfirmed">שמור נתונים מאקסל</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        importedSuccess: Array,
        importedFailed: Array,
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressSave: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        sendCall: false,
    }),

    methods: {
        actionConfirmed() {
            this.saveImported();
        },
        refreshPage() {
            this.openMode = false;
        },
        async saveImported() {
            const filteredCodesJSON = JSON.stringify({ products: this.importedSuccess});
            this.progressSave = true;
            let token = localStorage.getItem("token");
            try {
                let api = process.env.VUE_APP_BASE_URL + "/products/create_products_imported";
                const res = await fetch(api, ApiServices.requestOptions("POST", filteredCodesJSON, token));
                this.progressSave = false;
                const jsonObject = await res.json();
                if (res.status === 200) {
                    this.openMode = false;
                    this.$emit("importedSuccessAdded");
                } else {
                    this.showSnackBar('שגיאה: ' + jsonObject, "red");
                }
            } catch (error) {
                this.progressSave = false;
                this.showSnackBar("Error saved subscribes: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
}
</script>
  
    